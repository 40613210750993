import { GRAPHQL_AUTH_MODE } from '@aws-amplify/api'
import { StorageManager } from '@aws-amplify/ui-react-storage'
import { Delete } from '@mui/icons-material'
import DescriptionIcon from '@mui/icons-material/Description'
import {
  Alert,
  AlertTitle,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  IconButton,
  LinearProgress
} from '@mui/material'
import { API, Storage } from 'aws-amplify'
import dayjs from 'dayjs'
import { useContext, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { v4 } from 'uuid'
import { updateUserdataFilesQuery } from '../graphql/mutation/userData'
import { UserContext } from './DataContextProvider'
import { processFileForDocUpload } from './utilities'

const deductionFileTypes = {
  '80C': '80C',
  '80D': '80D',
  '80G/80GGC': '80G/80GGC',
  '80E': '80E',
  '80U': '80U',
  '80DD': '80DD',
  otherDeductions: 'otherDeductions'
}
export const fileFormValues = [
  {
    services: 'all',
    name: deductionFileTypes['80C'],
    title: '80C',
    note: `Most common are LIC premium, Tuition fees of child,Principal Repayment of Home Loan, ELSS/Tax Saving Fund,PPF Account,Employee PF ,Pension Scheme,Sukanya Samridhhi Account,etc`,
    docType: 'Documentary Evidence'
  },
  {
    services: 'all',
    name: deductionFileTypes['80D'],
    title: '80D',
    note: 'Mediclaim Insurance of Self/Parents/Family',
    docType: 'Premium Paid Receipt'
  },
  {
    services: 'all',
    name: deductionFileTypes['80G/80GGC'],
    title: '80G/80GGC',
    docType: 'Donation Receipt',
    note: 'Donation to Trust/Political Party'
  },
  {
    services: 'all',
    name: deductionFileTypes['80E'],
    title: '80E',
    docType: 'Interest Certificate',
    note: 'Interest on Educational Loan of Self/Spouse/Children for higher studies i.e. after passing senior secondary'
  },
  {
    services: 'all',
    name: deductionFileTypes['80U'],
    title: '80U',
    docType: 'Medical Certificate',
    note: 'If individual suffers disability >=40% as certifed by medical authority Disability means Blindness,Low vision,Leprosy-cured,Hearing impairment,Loco motor disability, Mental retardation,Mental illness'
  },
  {
    services: 'all',
    name: deductionFileTypes['80DD'],
    title: '80DD',
    docType: 'Medical Certificate',
    note: (
      <>
        <span>
          Deduction for medical expenditure of dependent relative (spouse,
          children, parents, brothers & sisters), provided
        </span>
        <br />
        <span> Conditions to claims deduction:</span>
        <br />
        <span>a) 80U for self is not claimed</span>
        <br />
        <span>{`b) disability >=40%`}</span>
      </>
    )
  },
  {
    services: 'all',
    name: deductionFileTypes.otherDeductions,
    title: 'Any Other',
    note: 'Any Other applicable deductions'
  }
]

export const Deductions = () => {
  const [userData, setUserData] = useState()
  const navigate = useNavigate()
  const [files, setFiles] = useState()
  const [open, setOpen] = useState(false)

  const { userData: userD } = useContext(UserContext)

  const handleClickOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  useEffect(() => {
    if (userD) {
      setUserData(userD)
      setFiles(
        (userD.files || []).reduce((acc, crr) => {
          if (Object.values(deductionFileTypes).includes(crr.proofType)) {
            return {
              ...acc,
              [crr.proofType]: crr
            }
          }
          return acc
        }, {})
      )
    }
  }, [userD])

  const updateUserDataFiles = userFilesState => {
    API.graphql({
      query: updateUserdataFilesQuery,
      variables: {
        input: {
          ...userData,
          files: Object.values(userFilesState).filter(v => v)
        }
      },
      authMode: GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS
    })
  }

  return (
    <div>
      <Grid display="flex" justifyContent="center" alignItems="center">
        <Grid
          container
          sx={{
            p: 4,
            maxWidth: 1200,
            zIndex: 0
          }}
          justifyContent="center"
          gap={3}
          alignItems="center"
        >
          <Alert severity="info">
            <AlertTitle>
              Simply upload the following files, relevant to the ITR plan chosen
            </AlertTitle>
            *NOTE: If more than 1 file then kindly zip and upload
          </Alert>
          {files &&
            fileFormValues.map(fileDetails => {
              return (
                <Grid
                  key={fileDetails.name}
                  sx={{
                    padding: '16px',
                    borderRadius: '8px',
                    boxShadow: '0px 10px 15px -3px rgba(0,0,0,0.1);'
                  }}
                  item
                  xs={12}
                  md={9}
                >
                  <Grid
                    sx={{
                      display: 'flex',
                      justifyContent: 'center'
                    }}
                    gap={4}
                    container
                  >
                    <Grid
                      item
                      xs={12}
                      md={8}
                      sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'flex-start',
                        fontWeight: 'bold',
                        '.descriptionText': {
                          fontWeight: 'normal',
                          fontSize: '14px'
                        }
                      }}
                    >
                      {fileDetails.title} :
                      <div className="descriptionText">
                        Document type: {fileDetails.docType}
                      </div>
                      <div className="descriptionText">
                        <i>{fileDetails.note}</i>
                      </div>
                    </Grid>
                    <Grid item xs={12} md={3}>
                      <StorageManager
                        accessLevel={'protected'}
                        acceptedFileTypes={['.pdf', '.zip', '.rar']}
                        isResumable={false}
                        showThumbnails={true}
                        maxFileCount={1}
                        maxFileSize={5000000}
                        defaultFiles={[
                          {
                            id: files[fileDetails.name]?.file,
                            status: 'uploaded',
                            progress: 100,
                            key: files[fileDetails.name]?.file,
                            error: null,
                            isImage: false
                          }
                        ]}
                        path={`${userData?.firstName}_${userData?.middleName}_${userData?.lastName}/`}
                        components={{
                          Container({ children }) {
                            return (
                              <Grid
                                sx={{
                                  width: '100%'
                                }}
                              >
                                {children}
                              </Grid>
                            )
                          },
                          DropZone({
                            children,
                            displayText,
                            inDropZone,
                            ...rest
                          }) {
                            return <>{children}</>
                          },
                          FilePicker({ onClick }) {
                            return files[fileDetails.name]?.file ? null : (
                              <Button variant="outlined" onClick={onClick}>
                                Browse Files
                              </Button>
                            )
                          },
                          FileListHeader() {
                            return <></>
                          },
                          FileList({
                            files: filesFromPicker,
                            onCancelUpload,
                            onDeleteUpload
                          }) {
                            return (
                              <Grid
                                container
                                sx={{
                                  width: '100%'
                                }}
                              >
                                {filesFromPicker.map(
                                  ({
                                    file,
                                    key,
                                    progress,
                                    status,
                                    uploadTask,
                                    error
                                  }) => {
                                    return (
                                      <Grid
                                        sx={{
                                          width: '100%',
                                          fontSize: '12px',
                                          color:
                                            status === 'error'
                                              ? 'red'
                                              : 'inherit'
                                        }}
                                        item
                                        xs={12}
                                        key={key}
                                      >
                                        <Grid
                                          sx={{
                                            width: '100%',

                                            justifyContent: 'space-between',
                                            alignItems: 'center',
                                            display: 'flex'
                                          }}
                                        >
                                          <Grid
                                            sx={{
                                              alignItems: 'center',
                                              display: 'flex'
                                            }}
                                          >
                                            <DescriptionIcon
                                              color="primary"
                                              sx={{
                                                mr: '8px'
                                              }}
                                            />
                                            {file?.name ||
                                              files[fileDetails.name]?.fileName}
                                          </Grid>
                                          <IconButton
                                            size="small"
                                            variant="outlined"
                                            onClick={() => {
                                              if (status === 'uploading') {
                                                onCancelUpload({
                                                  id: key,
                                                  uploadTask
                                                })
                                              } else {
                                                onDeleteUpload({
                                                  id: key
                                                })
                                              }
                                            }}
                                          >
                                            <Delete />
                                          </IconButton>
                                        </Grid>
                                        {progress < 100 && progress >= 0 ? (
                                          <LinearProgress
                                            variant="determinate"
                                            value={progress}
                                          />
                                        ) : (
                                          error
                                        )}
                                      </Grid>
                                    )
                                  }
                                )}
                              </Grid>
                            )
                          }
                        }}
                        onUploadStart={({ key }) => {
                          setFiles(prev => {
                            return {
                              ...files,
                              [fileDetails.name]: {
                                uploading: true,
                                file: key
                              }
                            }
                          })
                        }}
                        onUploadError={(...err) => {
                          setFiles(prev => {
                            return {
                              ...files,
                              [fileDetails.name]: {
                                uploading: false,
                                file: ''
                              }
                            }
                          })
                        }}
                        onUploadSuccess={({ key }) => {
                          setFiles(prev => {
                            const [_i1, _i2, _i3, proofType, fileName] = key
                              .split('/')[1]
                              .split('_')
                            const newState = {
                              ...files,
                              [fileDetails.name]: {
                                proofType,
                                fileName,
                                id: v4(),
                                uploadedOn: dayjs().format(),
                                file: key
                              }
                            }
                            updateUserDataFiles(newState)
                            return newState
                          })
                        }}
                        onFileRemove={({ key }) => {
                          if (files[fileDetails.name]) {
                            Storage.remove(files[fileDetails.name].file, {
                              level: 'protected'
                            }).then(() => {
                              setFiles(prev => {
                                const newState = {
                                  ...prev,
                                  [fileDetails.name]: undefined
                                }
                                updateUserDataFiles(newState)
                                return newState
                              })
                            })
                          } else {
                            setFiles(prev => {
                              const newState = {
                                ...prev,
                                [fileDetails.name]: undefined
                              }
                              return newState
                            })
                          }
                        }}
                        processFile={vals => {
                          return processFileForDocUpload({
                            ...vals,
                            type: fileDetails.name,
                            userData
                          })
                        }}
                      ></StorageManager>
                    </Grid>
                  </Grid>
                </Grid>
              )
            })}
          <Grid sx={{}} item xs={12} md={9}>
            <Alert severity="info">
              Please note that the allowability of deductions will be subject to
              the provisions of the Income Tax Act 1961, evidence submitted and
              may vary on case to case basis.
            </Alert>
          </Grid>

          <Grid
            sx={{
              display: 'flex'
            }}
            item
            xs={12}
            justifyContent="center"
          >
            <Button
              children="Finish"
              variant="contained"
              onClick={() => {
                if (!Object.values(files).length) {
                  handleClickOpen()
                } else {
                  navigate('/finalSteps')
                }
              }}
            ></Button>
          </Grid>
        </Grid>
      </Grid>

      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{'No Files Uploaded'}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            You have not uploaded any files. We will require files for
            processing ITR. Do you wish to continue without deductions?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button
            onClick={() => {
              handleClose()
              navigate('/finalSteps')
            }}
            autoFocus
          >
            Ok
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}
