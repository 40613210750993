import { Card, CardContent, Grid, Typography } from '@mui/material'
import { uniqueId } from 'lodash'
import * as React from 'react'
const cards = [
  {
    title:
      'Simply select the income sources for automatic selection of pricing plan.'
  },
  {
    title: 'Upload the documents.'
  },
  {
    title:
      'Tax Expert reviews the documents, selects the optimum regime and prepares the return.'
  },
  {
    title: 'Once you confirm and payment is made, the return is filed.'
  }
]

export const WhyChooseUs = () => {
  return (
    <Grid
      container
      sx={{
        p: { md: '64px', xs: '16px' },
        flexDirection: { md: 'row', xs: 'column', justifyContent: 'center' }
      }}
      spacing={{ md: 4, xs: 0 }}
    >
      <Grid
        item
        xs={12}
        md={6}
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center'
        }}
      >
        <Typography
          variant="h4"
          sx={{
            color: 'inherit',
            textAlign: 'left',
            pl: { md: '50px', xs: '0' },
            fontWeight: 'bold'
          }}
        >
          Getting Started is so easy..
        </Typography>
      </Grid>
      <Grid
        container
        sx={{
          mt: { md: '32px', xs: '32px' },
          justifyContent: 'center'
        }}
        spacing={4}
      >
        {cards.map((card, index) => {
          return (
            <Grid key={uniqueId()} item xs={12} md={12 / 5}>
              <Card
                sx={{
                  minWidth: '100%',
                  minHeight: '100%',
                  borderRadius: '15px',
                  boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px',
                  position: 'relative',
                  display: 'inline-grid'
                }}
                variant="outlined"
              >
                <CardContent
                  sx={{
                    textAlign: 'center',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    textAlign: 'justify',
                    ['.stepNumber']: {
                      color: '#2C6384',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      border: '3px solid #2C6384',
                      borderRadius: '30px',
                      fontSize: '18px',
                      height: '40px',
                      width: '40px'
                    }
                  }}
                >
                  {/* <div className="stepNumber">{index + 1}</div>{' '} */}
                  <div>{card.title}</div>
                </CardContent>
              </Card>
            </Grid>
          )
        })}
      </Grid>
    </Grid>
  )
}
