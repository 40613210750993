import CallIcon from '@mui/icons-material/Call'
import EmailIcon from '@mui/icons-material/Email'
import LocationOnIcon from '@mui/icons-material/LocationOn'
import { Divider, Grid } from '@mui/material'
import Typography from '@mui/material/Typography'
import * as React from 'react'
import { useNavigate } from 'react-router-dom'
import logo from '../assets/logoWide.png'

export const Footer = () => {
  const navigate = useNavigate()

  return (
    <Grid
      container
      sx={{
        padding: '24px',
        boxShadow: '0px 2px 20px 9px rgba(0,0,0,0.2)',
        display: { xs: 'flex', md: 'flex' },
        justifyContent: { xs: 'center', md: 'space-between' },
        my: 0
      }}
    >
      <Grid
        item
        xs={12}
        md={4}
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: { xs: 'center', md: 'start' },
          ml: { xs: '-50px', md: '0' },
          py: 0
        }}
      >
        <img
          src={logo}
          style={{
            height: '100px',
            marginLeft: '50px'
          }}
        />
      </Grid>
      <Divider
        sx={{
          margin: '16px 0',
          width: '100%',
          display: { xs: 'block', md: 'none' }
        }}
      />
      <Grid
        item
        xs={12}
        md={3}
        sx={{ justifyContent: { xs: 'center', md: 'start' } }}
      >
        <Typography
          variant="h6"
          sx={{
            display: 'flex',
            justifyContent: { xs: 'center', md: 'start' }
          }}
        >
          Quick Links
        </Typography>
        <Typography
          sx={{
            ':hover': {
              cursor: 'pointer'
            },
            display: 'flex',
            justifyContent: { xs: 'center', md: 'start' }
          }}
          variant="subtitle1"
          component="div"
          onClick={() => {
            navigate('/aboutUs')
          }}
        >
          About Us
        </Typography>
        <Typography
          sx={{
            ':hover': {
              cursor: 'pointer'
            },
            display: 'flex',
            justifyContent: { xs: 'center', md: 'start' }
          }}
          variant="subtitle1"
          component="div"
          onClick={() => {
            new Promise(resolve => {
              return resolve(navigate('/'))
            }).then(() => {
              setTimeout(() => {
                document
                  .getElementById('taxCheckboxes')
                  .scrollIntoView({ behavior: 'smooth' })
              }, 200)
            })
          }}
        >
          Pricing
        </Typography>
        <Typography
          sx={{
            ':hover': {
              cursor: 'pointer'
            },
            display: 'flex',
            justifyContent: { xs: 'center', md: 'start' }
          }}
          variant="subtitle1"
          component="div"
          onClick={() => {
            navigate('/blog')
          }}
        >
          Blog
        </Typography>
        <Typography
          sx={{
            ':hover': {
              cursor: 'pointer'
            },
            display: 'flex',
            justifyContent: { xs: 'center', md: 'start' }
          }}
          variant="subtitle1"
          component="div"
          onClick={() => {
            navigate('/policy')
          }}
        >
          Policy
        </Typography>
      </Grid>
      <Divider
        sx={{
          margin: '16px 0',
          width: '100%',
          display: { xs: 'block', md: 'none' }
        }}
      />
      <Grid
        item
        xs={12}
        md={5}
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: { xs: 'center', md: 'start' },
          svg: { mr: '16px' }
        }}
      >
        <Typography
          variant="h6"
          sx={{
            display: 'flex',
            justifyContent: { xs: 'center', md: 'start' }
          }}
        >
          Contact Us
        </Typography>
        <Typography
          variant="subtitle1"
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: { xs: 'center', md: 'start' }
          }}
          component="div"
        >
          <CallIcon /> +91 9420497294
        </Typography>
        <Typography
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: { xs: 'center', md: 'start' },
            ':hover': {
              cursor: 'pointer'
            }
          }}
          variant="subtitle1"
          component="div"
          onClick={() => {
            window.open('mailto:thetaxproject.info@gmail.com')
          }}
        >
          <EmailIcon /> thetaxproject.info@gmail.com
        </Typography>
        <Typography
          sx={{
            display: 'flex',
            justifyContent: { xs: 'center', md: 'start' },
            alignItems: 'center'
          }}
          variant="subtitle1"
          component="div"
        >
          <LocationOnIcon />
          <div>
            C-304, Space Olympia, Garkheda, Aurangabad, Maharashtra, India
            431001
          </div>
        </Typography>
      </Grid>
    </Grid>
  )
}
