/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createUserData = /* GraphQL */ `
  mutation CreateUserData(
    $input: CreateUserDataInput!
    $condition: ModelUserDataConditionInput
  ) {
    createUserData(input: $input, condition: $condition) {
      id
      firstName
      middleName
      lastName
      PAN
      additionalNotes
      dob
      registired
      files {
        uploadedOn
        id
        file
        fileName
        proofType
        __typename
      }
      phone
      Address
      Photo
      identityId
      userId
      services
      itrStatus
      email
      referenceCoupon
      paymentDetails {
        transactionId
        amout
        paymentStatus
        date
        service
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const updateUserData = /* GraphQL */ `
  mutation UpdateUserData(
    $input: UpdateUserDataInput!
    $condition: ModelUserDataConditionInput
  ) {
    updateUserData(input: $input, condition: $condition) {
      id
      firstName
      middleName
      lastName
      PAN
      additionalNotes
      dob
      registired
      files {
        uploadedOn
        id
        file
        fileName
        proofType
        __typename
      }
      phone
      Address
      Photo
      identityId
      userId
      services
      itrStatus
      email
      referenceCoupon
      paymentDetails {
        transactionId
        amout
        paymentStatus
        date
        service
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const deleteUserData = /* GraphQL */ `
  mutation DeleteUserData(
    $input: DeleteUserDataInput!
    $condition: ModelUserDataConditionInput
  ) {
    deleteUserData(input: $input, condition: $condition) {
      id
      firstName
      middleName
      lastName
      PAN
      additionalNotes
      dob
      registired
      files {
        uploadedOn
        id
        file
        fileName
        proofType
        __typename
      }
      phone
      Address
      Photo
      identityId
      userId
      services
      itrStatus
      email
      referenceCoupon
      paymentDetails {
        transactionId
        amout
        paymentStatus
        date
        service
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const createCupons = /* GraphQL */ `
  mutation CreateCupons(
    $input: CreateCuponsInput!
    $condition: ModelCuponsConditionInput
  ) {
    createCupons(input: $input, condition: $condition) {
      id
      code
      discountValue
      referenceEmail
      active
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const updateCupons = /* GraphQL */ `
  mutation UpdateCupons(
    $input: UpdateCuponsInput!
    $condition: ModelCuponsConditionInput
  ) {
    updateCupons(input: $input, condition: $condition) {
      id
      code
      discountValue
      referenceEmail
      active
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const deleteCupons = /* GraphQL */ `
  mutation DeleteCupons(
    $input: DeleteCuponsInput!
    $condition: ModelCuponsConditionInput
  ) {
    deleteCupons(input: $input, condition: $condition) {
      id
      code
      discountValue
      referenceEmail
      active
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const createLeads = /* GraphQL */ `
  mutation CreateLeads(
    $input: CreateLeadsInput!
    $condition: ModelLeadsConditionInput
  ) {
    createLeads(input: $input, condition: $condition) {
      id
      email
      quote
      capitalGains
      crypto
      futureOptions
      ifb
      salary
      salaryIncome
      phone
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const updateLeads = /* GraphQL */ `
  mutation UpdateLeads(
    $input: UpdateLeadsInput!
    $condition: ModelLeadsConditionInput
  ) {
    updateLeads(input: $input, condition: $condition) {
      id
      email
      quote
      capitalGains
      crypto
      futureOptions
      ifb
      salary
      salaryIncome
      phone
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const deleteLeads = /* GraphQL */ `
  mutation DeleteLeads(
    $input: DeleteLeadsInput!
    $condition: ModelLeadsConditionInput
  ) {
    deleteLeads(input: $input, condition: $condition) {
      id
      email
      quote
      capitalGains
      crypto
      futureOptions
      ifb
      salary
      salaryIncome
      phone
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
