export const createUserDataQuery = `mutation CreateUserData($input: CreateUserDataInput!) {
    createUserData(input: $input) {
      Address
      PAN
      dob
      firstName
      id
      identityId
      itrStatus
      email
      referenceCoupon
      lastName
      middleName
      registired
      phone
      _version
      services
      additionalNotes
      userId
    }
  }`

export const updateUserdataQuery = `mutation UpdateUserData($input: UpdateUserDataInput!) {
    updateUserData(input: $input) {
      Address
      PAN
      dob
      firstName
      identityId
      itrStatus
      email
      referenceCoupon
      id
      lastName
      middleName
      _version
      registired
      phone
      services
      additionalNotes
      userId
    }
  }`
export const updateUserdataFilesQuery = `mutation UpdateUserData($input: UpdateUserDataInput!) {
    updateUserData(input: $input) {
      firstName
      id
      lastName
      additionalNotes
      middleName
      identityId
      itrStatus
      email
      referenceCoupon
      services
      _version
      userId
      files {
        id
        fileName
        file
        proofType
        uploadedOn
      }
    }
  }`
