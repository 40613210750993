import { GRAPHQL_AUTH_MODE } from '@aws-amplify/api'
import {
  Alert,
  AlertTitle,
  Button,
  Grid,
  Snackbar,
  TextField
} from '@mui/material'
import { API } from 'aws-amplify'
import _ from 'lodash'
import { useContext, useEffect, useState } from 'react'
import { updateUserdataFilesQuery } from '../graphql/mutation/userData'
import { byUserEmail } from '../graphql/queries'
import { UserContext } from './DataContextProvider'

export const FinalSteps = () => {
  const [userData, setUserData] = useState()
  const [code, setCode] = useState()
  const [open, setOpen] = useState(false)
  const [showThankYou, setShowThankYou] = useState(false)

  const { userData: userD, setUserData: setContextUserData } =
    useContext(UserContext)

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return
    }

    setOpen(false)
  }

  useEffect(() => {
    if (userD) {
      setUserData(userD)
      userD.additionalNotes && setShowThankYou(true)
      API.graphql({
        query: byUserEmail,
        variables: {
          referenceEmail: userD.email
        }
      }).then(
        ({
          data: {
            byUserEmail: { items }
          }
        }) => {
          setCode(items[0])
        }
      )
    }
  }, [userD])

  const updateAdditionalNotes = async () => {
    if (!_.isEqual(userD, userData)) {
      const response = await API.graphql({
        query: updateUserdataFilesQuery,
        variables: {
          input: {
            ...userData,
            irtStatus: userData.itrStatus || 'underProcess'
          }
        },
        authMode: GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS
      })
      setShowThankYou(true)
      setContextUserData({
        ...response.data.updateUserData
      })
    }
  }

  return (
    <div>
      <Grid display="flex" justifyContent="center" alignItems="center">
        <Grid
          container
          sx={{
            p: 4,
            maxWidth: 1200,
            zIndex: 0
          }}
          justifyContent="center"
          gap={3}
          alignItems="center"
        >
          <Grid
            sx={{
              padding: '16px',
              borderRadius: '8px',
              boxShadow: '0px 10px 15px -3px rgba(0,0,0,0.1);'
            }}
            item
            xs={12}
            md={9}
          >
            <TextField
              id="outlined-multiline-flexible"
              label="Feedback or Instructions"
              multiline
              maxRows={10}
              sx={{
                width: '100%'
              }}
              value={userData?.additionalNotes}
              onChange={e => {
                const value = e.target.value
                value.length < 4000 &&
                  setUserData({
                    ...userData,
                    additionalNotes: value
                  })
              }}
            />
          </Grid>
          <Grid
            sx={{
              display: 'flex'
            }}
            item
            xs={12}
            justifyContent="center"
          >
            <Button
              children="Submit"
              variant="contained"
              onClick={() => {
                !open && setOpen(true)
                updateAdditionalNotes()
              }}
            ></Button>
          </Grid>
          {showThankYou && (
            <Grid item xs={12} md={9}>
              <Alert severity="success">
                <AlertTitle>Thank You</AlertTitle>
                Thank you for trusting us. Our team of experts will now process
                your documents and prepare the return of income. In case of any
                additional information, we will reach out to you through mail.
                Once return is ready, the computation will be send for your
                final confirmations.
                <br />
                <br /> We genuinely appreciate any specific instructions or
                preferences you may have regarding the services we provide,
                including the processing of your documents and the preparation
                of your income tax return. Your input will guide us in tailoring
                our approach to meet your unique requirements effectively.
              </Alert>
            </Grid>
          )}
          {code?.active ? (
            <Grid item xs={12} md={9}>
              <Alert severity="info">
                <AlertTitle>Your Referral Code is {code.code}</AlertTitle>
                Please share it with you friends to avail 10% cashback of their
                fees.
              </Alert>
            </Grid>
          ) : null}
        </Grid>
      </Grid>
      <Snackbar
        open={open}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        autoHideDuration={6000}
        onClose={handleClose}
      >
        <Alert
          onClose={handleClose}
          severity="success"
          sx={{ height: '48px', width: '100%' }}
        >
          Application submitted successfully!
        </Alert>
      </Snackbar>
    </div>
  )
}
