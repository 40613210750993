import { Grid, Typography } from '@mui/material'
import * as React from 'react'
import comingSoon from '../assets/About Us page.jpg'
import { Footer } from './Footer'

export const ComingSoon = () => {
  return (
    <>
      <Grid
        container
        sx={{
          p: '32px',
          background: '#e1e7e8',
          justifyContent: 'center',
          flexDirection: 'column',
          height: 'calc(100vh - 132px)'
        }}
      >
        <Grid
          maxWidth="xl"
          container
          sx={{
            background: '#e1e7e8',
            flexDirection: { md: 'row', xs: 'column' },
            justifyContent: 'center'
          }}
        >
          <Typography
            variant="h4"
            sx={{
              color: 'inherit',
              pl: { md: '50px', xs: '0' },
              textAlign: 'justify',
              fontWeight: 'normal',
              '.highlight': {
                color: '#2C6384'
              }
            }}
          >
            Coming Soon
          </Typography>
        </Grid>
      </Grid>
      <Footer />
    </>
  )
}
export default comingSoon
