/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getUserData = /* GraphQL */ `
  query GetUserData($id: ID!) {
    getUserData(id: $id) {
      id
      firstName
      middleName
      lastName
      PAN
      additionalNotes
      dob
      registired
      files {
        uploadedOn
        id
        file
        fileName
        proofType
        __typename
      }
      phone
      Address
      Photo
      identityId
      userId
      services
      itrStatus
      email
      referenceCoupon
      paymentDetails {
        transactionId
        amout
        paymentStatus
        date
        service
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const listUserData = /* GraphQL */ `
  query ListUserData(
    $filter: ModelUserDataFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUserData(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        firstName
        middleName
        lastName
        PAN
        additionalNotes
        dob
        registired
        phone
        Address
        Photo
        identityId
        userId
        services
        itrStatus
        email
        referenceCoupon
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncUserData = /* GraphQL */ `
  query SyncUserData(
    $filter: ModelUserDataFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncUserData(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        firstName
        middleName
        lastName
        PAN
        additionalNotes
        dob
        registired
        phone
        Address
        Photo
        identityId
        userId
        services
        itrStatus
        email
        referenceCoupon
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const byUserId = /* GraphQL */ `
  query ByUserId(
    $userId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelUserDataFilterInput
    $limit: Int
    $nextToken: String
  ) {
    byUserId(
      userId: $userId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        firstName
        middleName
        lastName
        PAN
        additionalNotes
        dob
        registired
        phone
        Address
        Photo
        identityId
        userId
        services
        itrStatus
        email
        referenceCoupon
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getCupons = /* GraphQL */ `
  query GetCupons($id: ID!) {
    getCupons(id: $id) {
      id
      code
      discountValue
      referenceEmail
      active
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const listCupons = /* GraphQL */ `
  query ListCupons(
    $filter: ModelCuponsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCupons(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        code
        discountValue
        referenceEmail
        active
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncCupons = /* GraphQL */ `
  query SyncCupons(
    $filter: ModelCuponsFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncCupons(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        code
        discountValue
        referenceEmail
        active
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const byUserEmail = /* GraphQL */ `
  query ByUserEmail(
    $referenceEmail: AWSEmail!
    $sortDirection: ModelSortDirection
    $filter: ModelCuponsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    byUserEmail(
      referenceEmail: $referenceEmail
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        code
        discountValue
        referenceEmail
        active
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getLeads = /* GraphQL */ `
  query GetLeads($id: ID!) {
    getLeads(id: $id) {
      id
      email
      quote
      capitalGains
      crypto
      futureOptions
      ifb
      salary
      salaryIncome
      phone
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const listLeads = /* GraphQL */ `
  query ListLeads(
    $filter: ModelLeadsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listLeads(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        email
        quote
        capitalGains
        crypto
        futureOptions
        ifb
        salary
        salaryIncome
        phone
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncLeads = /* GraphQL */ `
  query SyncLeads(
    $filter: ModelLeadsFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncLeads(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        email
        quote
        capitalGains
        crypto
        futureOptions
        ifb
        salary
        salaryIncome
        phone
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
