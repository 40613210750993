import { Authenticator } from '@aws-amplify/ui-react'
import '@aws-amplify/ui-react/styles.css'
import { LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import './App.css'
import AboutUs from './custom-compponents/AboutUs'
import Dashboard from './custom-compponents/Dashboard'
import DataContextProvider from './custom-compponents/DataContextProvider'
import { Deductions } from './custom-compponents/Deductions'
import { DocumentUploadForm } from './custom-compponents/DocumentsUpload'
import { FinalSteps } from './custom-compponents/FinalSteps'
import { Landing } from './custom-compponents/Landing'
import LandingNavBar from './custom-compponents/LandingNavBar'
import { Login } from './custom-compponents/Login'
import RequireAuth from './custom-compponents/RequireAuth'
import UpdateProfile from './custom-compponents/UpdateProfile'
import { User } from './custom-compponents/User'
import { ComingSoon } from './custom-compponents/comingSoon'
function App() {
  return (
    <div
      className="App"
      style={{
        minHeight: '100vh'
      }}
    >
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <Authenticator.Provider>
          <BrowserRouter>
            <DataContextProvider>
              <LandingNavBar />
              <Routes>
                <Route path="/" element={<Landing />} />
                <Route path="/signIn" element={<Login />} />
                <Route path="/signUp" element={<Login />} />
                <Route path="/aboutUS" element={<AboutUs />} />
                {/* <Route path="/itr" element={<ComingSoon />} /> */}
                <Route path="/registrations" element={<ComingSoon />} />
                <Route path="/blog" element={<ComingSoon />} />
                <Route
                  path="/user"
                  element={
                    <RequireAuth>
                      <User />
                    </RequireAuth>
                  }
                />
                <Route
                  path="/uploadFiles"
                  element={
                    <RequireAuth>
                      <DocumentUploadForm />
                    </RequireAuth>
                  }
                />
                <Route
                  path="/deductions"
                  element={
                    <RequireAuth>
                      <Deductions />
                    </RequireAuth>
                  }
                />
                <Route
                  path="/finalSteps"
                  element={
                    <RequireAuth>
                      <FinalSteps />
                    </RequireAuth>
                  }
                />
                <Route
                  path="/profile"
                  element={
                    <RequireAuth>
                      <UpdateProfile />
                    </RequireAuth>
                  }
                />
                <Route
                  path="/dashboard"
                  element={
                    <RequireAuth>
                      <Dashboard />
                    </RequireAuth>
                  }
                />
              </Routes>
            </DataContextProvider>
          </BrowserRouter>
        </Authenticator.Provider>
      </LocalizationProvider>
    </div>
  )
}

export default App
