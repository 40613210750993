import { GRAPHQL_AUTH_MODE } from '@aws-amplify/api'
import { StorageManager } from '@aws-amplify/ui-react-storage'
import { Delete } from '@mui/icons-material'
import DescriptionIcon from '@mui/icons-material/Description'
import {
  Alert,
  AlertTitle,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  IconButton,
  LinearProgress
} from '@mui/material'
import { API, Storage } from 'aws-amplify'
import dayjs from 'dayjs'
import _ from 'lodash'
import { useContext, useEffect, useMemo, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { v4 } from 'uuid'
import { updateUserdataFilesQuery } from '../graphql/mutation/userData'
import { services } from './ChecklistSection'
import { UserContext } from './DataContextProvider'
import { processFileForDocUpload } from './utilities'

export const docUploadFileTypes = {
  form16Document: 'form16Document',
  rentAgreementDocument: 'rentAgreementDocument',
  houseLoanDocument: 'houseLoanDocument',
  capitalGainDocument: 'capitalGainDocument',
  immovableSaleDocument: 'immovableSaleDocument',
  immovablePurchaseDocument: 'immovablePurchaseDocument',
  futureOptionsDocument: 'futureOptionsDocument',
  interestIncomeDocument: 'interestIncomeDocument',
  dividendIncomeDocument: 'dividendIncomeDocument',
  cryptoDocument: 'cryptoDocument',
  otherIncomeDocument: 'otherIncomeDocument',
  bankStatementDocument: 'bankStatementDocument'
}
export const fileFormValues = [
  {
    services: services.salaryIncome,
    name: docUploadFileTypes.form16Document,
    title: 'Form 16 / Payslips',
    note: 'Upload Form 16(pdf) recieved from employer else upload all payslips in zip format.'
  },
  {
    services: services.rent,
    name: docUploadFileTypes.rentAgreementDocument,
    title: 'Rent Paid Documents',
    note: 'Upload Rent Agreement(pdf) or rent reciepts (zip file) or self declaration document containing details of PAN of landlord, monthly house rent, paid for calculating benefit of HRA exemption or 80GG deduction.'
  },
  {
    services: services.houseLoan,
    name: docUploadFileTypes.houseLoanDocument,
    title: 'Housing Loan Documents',
    note: 'Upload Interest certificate received from bank having details of amount paid towards principal and interest components.'
  },
  {
    services: services.mutualFund,
    name: docUploadFileTypes.capitalGainDocument,
    title: 'Capital Gain reports',
    note: 'Upload the Tax profit & loss report generated from the brokers platform/app.'
  },
  {
    services: services.immovableProperty,
    name: docUploadFileTypes.immovableSaleDocument,
    title: 'Immovable Property Sale Deed',
    note: 'Upload the copy of Sale Deed,with Index 2'
  },
  {
    services: services.immovableProperty,
    name: docUploadFileTypes.immovablePurchaseDocument,
    title: 'Immovable Property Purchase Deed',
    note: 'Upload the copy of Purchase Deed,with Index 2'
  },
  {
    services: services.fno,
    name: docUploadFileTypes.futureOptionsDocument,
    title: 'Futures/Options P&L report',
    note: 'Upload the Tax profit & loss report generated from the brokers platform/app.'
  },
  {
    services: services.interestIncome,
    name: docUploadFileTypes.interestIncomeDocument,
    title: 'Interest Income',
    note: 'Upload FD interest certifcate issued by Bank (For FDs) and Bank Statement for Savings Bank Interest'
  },
  {
    services: services.dividentIncome,
    name: docUploadFileTypes.dividendIncomeDocument,
    title: 'Dividend Income',
    note: 'Bank Statement connected with Dmat Account'
  },
  {
    services: services.crypto,
    name: docUploadFileTypes.cryptoDocument,
    title: 'Cryptocurrency P&L report ',
    note: 'Upload the Tax profit & loss report generated from the brokers platform/app.'
  },
  {
    services: 'all',
    name: docUploadFileTypes.otherIncomeDocument,
    title: 'Any other Income proof',
    note: 'Upload relevant document for any other income earned.'
  },
  {
    services: 'all',
    name: docUploadFileTypes.bankStatementDocument,
    title: 'Bank Statements',
    note: 'Upload copy of all bank statements in single pdf file or in zip file.'
  }
]

export const DocumentUploadForm = () => {
  const [userData, setUserData] = useState()
  const navigate = useNavigate()
  const [files, setFiles] = useState()
  const [open, setOpen] = useState(false)

  const servicesFromUserData = useMemo(() => {
    return JSON.parse(userData?.services || '{}')
  }, [userData?.services])

  const filteredFileList = useMemo(() => {
    if (userData?.services) {
      return fileFormValues.filter(formValue => {
        if (formValue.services === 'all') {
          return true
        }
        return servicesFromUserData[formValue.services]
      })
    } else {
      return []
    }
  }, [userData?.services])

  const { userData: userD, setUserData: setContextUserData } =
    useContext(UserContext)

  const handleClickOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  useEffect(() => {
    if (userD) {
      setUserData(userD)
      setFiles(
        (userD.files || []).reduce((acc, crr) => {
          if (Object.values(docUploadFileTypes).includes(crr.proofType)) {
            return {
              ...acc,
              [crr.proofType]: crr
            }
          }
          return acc
        }, {})
      )
    }
  }, [userD])

  const updateUserDataFiles = async userFilesState => {
    if (
      !_.isEqual(
        {
          ...userData,
          files: Object.values(userFilesState).filter(v => v)
        },
        userData
      )
    ) {
      const response = await API.graphql({
        query: updateUserdataFilesQuery,
        variables: {
          input: {
            ...userData,
            files: Object.values(userFilesState).filter(v => v)
          }
        },
        authMode: GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS
      })
      setContextUserData({
        ...userD,
        ...response.data.updateUserData
      })
    }
  }

  return (
    <div>
      <Grid display="flex" justifyContent="center" alignItems="center">
        <Grid
          container
          sx={{
            p: 4,
            maxWidth: 1200,
            zIndex: 0
          }}
          justifyContent="center"
          gap={3}
          alignItems="center"
        >
          <Alert severity="info">
            <AlertTitle>
              Simply upload the following files, relevant to the ITR plan chosen
            </AlertTitle>
            *NOTE: If more than 1 file then kindly zip and upload
          </Alert>
          {files &&
            filteredFileList.map(fileDetails => {
              return (
                <Grid
                  key={fileDetails.name}
                  sx={{
                    padding: '16px',
                    borderRadius: '8px',
                    boxShadow: '0px 10px 15px -3px rgba(0,0,0,0.1);'
                  }}
                  item
                  xs={12}
                  md={9}
                >
                  <Grid
                    sx={{
                      display: 'flex',
                      justifyContent: 'center'
                    }}
                    gap={4}
                    container
                  >
                    <Grid
                      item
                      xs={12}
                      md={8}
                      sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'flex-start',
                        fontWeight: 'bold',
                        span: {
                          fontWeight: 'normal',
                          fontSize: '14px'
                        }
                      }}
                    >
                      {fileDetails.title} :
                      <span>
                        <i>{fileDetails.note}</i>
                      </span>
                    </Grid>
                    <Grid item xs={12} md={3}>
                      <StorageManager
                        accessLevel={'protected'}
                        acceptedFileTypes={['.pdf', '.zip', '.rar']}
                        isResumable={false}
                        showThumbnails={true}
                        maxFileCount={1}
                        maxFileSize={5000000}
                        defaultFiles={[
                          {
                            id: files[fileDetails.name]?.file,
                            status: 'uploaded',
                            progress: 100,
                            key: files[fileDetails.name]?.file,
                            error: null,
                            isImage: false
                          }
                        ]}
                        path={`${userData?.firstName}_${userData?.middleName}_${userData?.lastName}/`}
                        components={{
                          Container({ children }) {
                            return (
                              <Grid
                                sx={{
                                  width: '100%'
                                }}
                              >
                                {children}
                              </Grid>
                            )
                          },
                          DropZone({
                            children,
                            displayText,
                            inDropZone,
                            ...rest
                          }) {
                            return <>{children}</>
                          },
                          FilePicker({ onClick }) {
                            return files[fileDetails.name]?.file ? null : (
                              <Button variant="outlined" onClick={onClick}>
                                Browse Files
                              </Button>
                            )
                          },
                          FileListHeader() {
                            return <></>
                          },
                          FileList({
                            files: filesFromPicker,
                            onCancelUpload,
                            onDeleteUpload
                          }) {
                            return (
                              <Grid
                                container
                                sx={{
                                  width: '100%'
                                }}
                              >
                                {filesFromPicker.map(
                                  ({
                                    file,
                                    key,
                                    progress,
                                    status,
                                    uploadTask,
                                    error
                                  }) => {
                                    return (
                                      <Grid
                                        sx={{
                                          width: '100%',
                                          fontSize: '12px',
                                          color:
                                            status === 'error'
                                              ? 'red'
                                              : 'inherit'
                                        }}
                                        item
                                        xs={12}
                                        key={key}
                                      >
                                        <Grid
                                          sx={{
                                            width: '100%',

                                            justifyContent: 'space-between',
                                            alignItems: 'center',
                                            display: 'flex'
                                          }}
                                        >
                                          <Grid
                                            sx={{
                                              alignItems: 'center',
                                              display: 'flex'
                                            }}
                                          >
                                            <DescriptionIcon
                                              color="primary"
                                              sx={{
                                                mr: '8px'
                                              }}
                                            />
                                            {file?.name ||
                                              files[fileDetails.name]?.fileName}
                                          </Grid>
                                          <IconButton
                                            size="small"
                                            variant="outlined"
                                            onClick={() => {
                                              if (status === 'uploading') {
                                                onCancelUpload({
                                                  id: key,
                                                  uploadTask
                                                })
                                              } else {
                                                onDeleteUpload({
                                                  id: key
                                                })
                                              }
                                            }}
                                          >
                                            <Delete />
                                          </IconButton>
                                        </Grid>
                                        {progress < 100 && progress >= 0 ? (
                                          <LinearProgress
                                            variant="determinate"
                                            value={progress}
                                          />
                                        ) : (
                                          error
                                        )}
                                      </Grid>
                                    )
                                  }
                                )}
                              </Grid>
                            )
                          }
                        }}
                        onUploadStart={({ key }) => {
                          setFiles(prev => {
                            return {
                              ...files,
                              [fileDetails.name]: {
                                uploading: true,
                                file: key
                              }
                            }
                          })
                        }}
                        onUploadError={(...err) => {
                          setFiles(prev => {
                            return {
                              ...files,
                              [fileDetails.name]: {
                                uploading: false,
                                file: ''
                              }
                            }
                          })
                        }}
                        onUploadSuccess={({ key }) => {
                          setFiles(prev => {
                            const [_i1, _i2, _i3, proofType, fileName] = key
                              .split('/')[1]
                              .split('_')
                            const newState = {
                              ...files,
                              [fileDetails.name]: {
                                proofType,
                                fileName,
                                id: v4(),
                                uploadedOn: dayjs().format(),
                                file: key
                              }
                            }
                            updateUserDataFiles(newState)
                            return newState
                          })
                        }}
                        onFileRemove={({ key }) => {
                          if (files[fileDetails.name]) {
                            Storage.remove(files[fileDetails.name].file, {
                              level: 'protected'
                            }).then(() => {
                              setFiles(prev => {
                                const newState = {
                                  ...prev,
                                  [fileDetails.name]: undefined
                                }
                                updateUserDataFiles(newState)
                                return newState
                              })
                            })
                          } else {
                            setFiles(prev => {
                              const newState = {
                                ...prev,
                                [fileDetails.name]: undefined
                              }
                              return newState
                            })
                          }
                        }}
                        processFile={vals => {
                          return processFileForDocUpload({
                            ...vals,
                            type: fileDetails.name,
                            userData
                          })
                        }}
                      ></StorageManager>
                    </Grid>
                  </Grid>
                </Grid>
              )
            })}

          {servicesFromUserData &&
          [
            servicesFromUserData.professionalIncomeBelow50,
            servicesFromUserData.businessTurnoverBelow2cr
          ].includes(true) ? (
            <Grid item xs={12} md={9}>
              <Alert severity="info">
                <AlertTitle>Business / Other Professions</AlertTitle>
                Our team will connect with you for required information.
              </Alert>
            </Grid>
          ) : null}

          <Grid
            sx={{
              display: 'flex'
            }}
            item
            xs={12}
            justifyContent="center"
          >
            <Button
              children="Next"
              variant="contained"
              onClick={() => {
                if (!Object.values(files).length) {
                  handleClickOpen()
                } else {
                  navigate('/deductions')
                }
              }}
            ></Button>
          </Grid>
        </Grid>
      </Grid>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{'No Files Uploaded'}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            You have not uploaded any files. We will require files for
            processing ITR. Do you wish to continue to deductions page?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button
            onClick={() => {
              handleClose()
              navigate('/deductions')
            }}
            autoFocus
          >
            Ok
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}
