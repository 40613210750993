import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Grid,
  InputAdornment,
  Slider,
  TextField,
  Typography,
  styled
} from '@mui/material'
import { API } from 'aws-amplify/lib-esm'
import { MuiTelInput } from 'mui-tel-input'
import * as React from 'react'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { v4 } from 'uuid'
import { createLeads } from '../graphql/mutation/createLeads'
import { validateField } from './utilities'

const checks = [
  {
    title: 'Capital Gains',
    value: 'capitalGains'
  },
  {
    title: 'Cryptocurrency Transactions',
    value: 'crypto'
  },
  {
    title: 'Futures & Options ( Derivatives)',
    value: 'futureOptions'
  },
  {
    title:
      'Income from Business(Below 2 Cr) & Profession (Non Audit W/o Balance Sheet case)',
    value: 'ifb'
  }
]

const iOSBoxShadow =
  '0 3px 1px rgba(0,0,0,0.1),0 4px 8px rgba(0,0,0,0.13),0 0 0 1px rgba(0,0,0,0.02)'
const IOSSlider = styled(Slider)(({ theme }) => ({
  color: theme.palette.mode === 'dark' ? '#3880ff' : '#3880ff',
  height: 2,
  padding: '15px 0',
  '& .MuiSlider-thumb': {
    height: 28,
    width: 28,
    backgroundColor: '#fff',
    boxShadow: iOSBoxShadow,
    '&:focus, &:hover, &.Mui-active': {
      boxShadow:
        '0 3px 1px rgba(0,0,0,0.1),0 4px 8px rgba(0,0,0,0.3),0 0 0 1px rgba(0,0,0,0.02)',
      // Reset on touch devices, it doesn't add specificity
      '@media (hover: none)': {
        boxShadow: iOSBoxShadow
      }
    }
  },
  '& .MuiSlider-valueLabel': {
    fontSize: 12,
    fontWeight: 'normal',
    top: -6,
    backgroundColor: 'unset',
    color: theme.palette.text.primary,
    '&:before': {
      display: 'none'
    },
    '& *': {
      background: 'transparent',
      color: theme.palette.mode === 'dark' ? '#fff' : '#000'
    }
  },
  '& .MuiSlider-track': {
    border: 'none'
  },
  '& .MuiSlider-rail': {
    opacity: 0.5,
    backgroundColor: '#bfbfbf'
  },
  '& .MuiSlider-mark': {
    backgroundColor: '#bfbfbf',
    height: 8,
    width: 1,
    '&.MuiSlider-markActive': {
      opacity: 1,
      backgroundColor: 'currentColor'
    }
  }
}))

const marks = [
  {
    value: 5,
    label: '5L'
  },
  {
    value: 10,
    label: '10L'
  },
  {
    value: 50,
    label: '50L'
  },
  {
    value: 100,
    label: '1CR +'
  }
]

const validations = {
  email: [{ type: 'Required' }, { type: 'Email' }],
  quote: [],
  capitalGains: [],
  crypto: [],
  futureOptions: [],
  ifb: [],
  salary: [],
  salaryIncome: [],
  phone: [
    { type: 'Required' },
    { type: 'Phone' },
    { type: 'LessThanChar', numValues: [16] },
    { type: 'GreaterThanChar', numValues: [14] }
  ]
}

export const Section3 = () => {
  const navigate = useNavigate()
  const [formVlaues, setFormValues] = useState({
    salary: 20,
    salaryIncome: false,
    capitalGains: false,
    crypto: false,
    futureOptions: false,
    ifb: false
  })
  const [errors, setErrors] = React.useState({})
  const [finalQuote, setQuote] = useState(0)
  const updateState = e => {
    setQuote(0)
    setFormValues({
      ...formVlaues,
      [e.target.name]:
        e.target.type === 'checkbox' ? e.target.checked : e.target.value
    })
  }

  const runValidationTasks = async (
    fieldName,
    currentValue,
    getDisplayValue
  ) => {
    const value =
      currentValue && getDisplayValue
        ? getDisplayValue(currentValue)
        : currentValue
    let validationResponse = validateField(value, validations[fieldName])
    setErrors(errors => ({ ...errors, [fieldName]: validationResponse }))
    return validationResponse
  }

  const generateQuote = async () => {
    let modelFields = {
      ...formVlaues
    }
    const validationResponses = await Promise.all(
      Object.keys(validations).reduce((promises, fieldName) => {
        if (Array.isArray(modelFields[fieldName])) {
          promises.push(
            ...modelFields[fieldName].map(item =>
              runValidationTasks(fieldName, item)
            )
          )
          return promises
        }
        promises.push(runValidationTasks(fieldName, modelFields[fieldName]))
        return promises
      }, [])
    )
    if (validationResponses.some(r => r.hasError)) {
      return
    }

    const { salaryIncome, ifb, crypto, salary, capitalGains, futureOptions } =
      formVlaues
    let quote = 0
    if (salaryIncome) {
      if (salary <= 5) {
        quote = quote + 750
      } else if (salary <= 10) {
        quote = quote + 1250
      } else if (salary <= 50) {
        quote = quote + 2000
      } else {
        quote = quote + 2500
      }
    }
    if (capitalGains) {
      quote = quote + 2000
    }
    if (crypto) {
      quote = quote + 1500
    }
    if (futureOptions) {
      quote = quote + 1000
    }
    if (ifb) {
      quote = quote + 2500
    }
    setQuote(quote)
    try {
      modelFields = {
        ...modelFields,
        id: v4(),
        quote
      }
      Object.entries(modelFields).forEach(([key, value]) => {
        if (typeof value === 'string' && value.trim() === '') {
          modelFields[key] = undefined
        }
        if (value === null) {
          modelFields[key] = false
        }
      })

      await API.graphql({
        query: createLeads,
        variables: {
          input: modelFields
        }
      })
    } catch (error) {
      alert(error)
    }
  }
  return (
    <div id="taxCheckboxes">
      <FormGroup>
        <Grid
          component="form"
          sx={{
            mt: { md: '50px', xs: '50px' },
            justifyContent: 'center',
            p: '32px'
          }}
          container
        >
          <Grid
            item
            sx={{
              textAlign: 'center'
            }}
            md={8}
            xs={12}
          >
            <Typography
              gutterBottom
              variant="h4"
              sx={{ textAlign: 'center', fontWeight: 'bold' }}
              component="div"
            >
              Affordable Pricing
            </Typography>
            <Grid
              container
              sx={{
                display: 'flex',
                alignItems: 'center'
              }}
            >
              <Grid item xs={12} md={6}>
                <Typography
                  sx={{
                    margin: '8px 8px 8px 4px'
                  }}
                  gutterBottom
                  variant="body1"
                  component="div"
                >
                  Email :{' '}
                  <FormControlLabel
                    sx={{
                      margin: 0,
                      width: { xs: '67%', md: '80%' }
                    }}
                    onChange={updateState}
                    name="email"
                    control={
                      <TextField
                        error={errors.email?.hasError}
                        helperText={errors.email?.hasError && 'Invalid Email'}
                        name="email"
                        size="small"
                        sx={{
                          margin: 0,
                          width: { xs: '100%', md: '100%' }
                        }}
                        type="email"
                      />
                    }
                  />
                </Typography>
              </Grid>

              <Grid item xs={12} md={6}>
                <Typography
                  sx={{
                    margin: '8px 8px 8px 4px'
                  }}
                  gutterBottom
                  variant="body1"
                  component="div"
                >
                  Phone :{' '}
                  <FormControlLabel
                    sx={{
                      margin: 0,
                      width: { xs: '67%', md: '80%' }
                    }}
                    value={formVlaues.phone}
                    name="phone"
                    onChange={value => {
                      value.replaceAll(/\s/g, '').length <= 13 &&
                        updateState({
                          target: {
                            name: 'phone',
                            value
                          }
                        })
                    }}
                    control={
                      <MuiTelInput
                        error={errors.phone?.hasError}
                        helperText={errors.phone?.hasError && 'Invalid Phone'}
                        sx={{
                          margin: 0,
                          width: { xs: '100%', md: '100%' }
                        }}
                        size="small"
                        name="phone"
                        defaultCountry="IN"
                        onlyCountries={['IN']}
                      />
                    }
                  />
                </Typography>
              </Grid>
            </Grid>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center'
              }}
            >
              <FormControlLabel
                onChange={updateState}
                sx={{
                  '.MuiCheckbox-root': {
                    padding: '0 9px'
                  },
                  '&.MuiFormControlLabel-root': {
                    alignItems: 'flex-start',
                    fontSize: '14px'
                  }
                }}
                disabled={!formVlaues.email || !formVlaues.phone}
                name={'salaryIncome'}
                control={<Checkbox />}
                label="Salary Income :"
              />
              <FormControlLabel
                disabled={!formVlaues?.salaryIncome}
                onChange={updateState}
                value={formVlaues.salary}
                name={'salary'}
                control={
                  <TextField
                    size="small"
                    sx={{ m: 1, width: '130px' }}
                    type="number"
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">₹</InputAdornment>
                      ),
                      endAdornment: (
                        <InputAdornment position="start">Lacs</InputAdornment>
                      )
                    }}
                  />
                }
              />
            </Box>
            <Box
              sx={{
                width: { md: '70%', xs: '100%' },
                alignItems: 'center'
              }}
            >
              <IOSSlider
                disabled={!formVlaues?.salaryIncome}
                value={formVlaues.salary}
                defaultValue={formVlaues.salary}
                onChange={e => {
                  setQuote(0)
                  setFormValues({
                    ...formVlaues,
                    salary: e.target.value
                  })
                }}
                valueLabelDisplay="auto"
                marks={marks}
              />
            </Box>
            {checks.map(check => {
              return (
                <FormControlLabel
                  onChange={updateState}
                  key={check.value}
                  sx={{
                    '.MuiCheckbox-root': {
                      padding: '0 9px'
                    },
                    '&.MuiFormControlLabel-root': {
                      alignItems: 'flex-start',
                      fontSize: '14px',
                      width: '100%'
                    }
                  }}
                  disabled={!formVlaues.email || !formVlaues.phone}
                  name={check.value}
                  control={<Checkbox />}
                  label={check.title}
                />
              )
            })}

            {finalQuote ? (
              <Typography
                gutterBottom
                variant="h6"
                sx={{ textAlign: 'center', fontWeight: 'bold' }}
                component="div"
              >
                Your customised Pricing Plan is for ₹{finalQuote}/- Only
              </Typography>
            ) : (
              <>
                <Button
                  sx={{
                    mt: '24px',
                    mx: '16px'
                  }}
                  disabled={!formVlaues.email || !formVlaues.phone}
                  variant="contained"
                  onClick={generateQuote}
                  size="large"
                >
                  Get instant Quote
                </Button>
                <Button
                  sx={{
                    mt: '24px',
                    mx: '16px'
                  }}
                  onClick={() => navigate('/signUp')}
                  variant="contained"
                  size="large"
                >
                  Get started
                </Button>
              </>
            )}
          </Grid>
        </Grid>
      </FormGroup>
    </div>
  )
}
