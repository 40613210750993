/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "ap-south-1",
    "aws_appsync_graphqlEndpoint": "https://6nwreeww4zblbksi34vpzzcwya.appsync-api.ap-south-1.amazonaws.com/graphql",
    "aws_appsync_region": "ap-south-1",
    "aws_appsync_authenticationType": "API_KEY",
    "aws_appsync_apiKey": "da2-wcsazea57bebbc4az3uahnj67i",
    "aws_cognito_identity_pool_id": "ap-south-1:20bea88b-2ca2-4061-b35e-ed5de8774b67",
    "aws_cognito_region": "ap-south-1",
    "aws_user_pools_id": "ap-south-1_XpdNK0tyk",
    "aws_user_pools_web_client_id": "2u67ov52higm5mu51445ec9jer",
    "oauth": {},
    "aws_cognito_username_attributes": [
        "EMAIL"
    ],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": [
            "REQUIRES_LOWERCASE",
            "REQUIRES_NUMBERS",
            "REQUIRES_SYMBOLS",
            "REQUIRES_UPPERCASE"
        ]
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_user_files_s3_bucket": "thetaxproject-storage-76d9e4ff1e0a1-main",
    "aws_user_files_s3_bucket_region": "ap-south-1"
};


export default awsmobile;
