import { useAuthenticator } from '@aws-amplify/ui-react'
import React from 'react'
import { Navigate, useLocation } from 'react-router-dom'

const RequireAuth = ({ children }) => {
  const location = useLocation()
  const { route } = useAuthenticator(context => [context.route])
  if (route !== 'authenticated') {
    return <Navigate to="/signIn" state={{ from: location }} replace />
  }
  return children
}

export default RequireAuth
