import { useAuthenticator } from '@aws-amplify/ui-react'
import { useContext, useEffect, useState } from 'react'
import { UserContext } from './DataContextProvider'
import OnboardingForm from './Onboarding'
export const UpdateProfile = () => {
  const [userData, setUserData] = useState()
  const { user } = useAuthenticator(context => [context.route])
  const { userData: userD, setUserData: setContextUserData } =
    useContext(UserContext)
  useEffect(() => {
    if (userD) {
      setUserData(userD)
    }
  }, [userD])

  return userData ? (
    <OnboardingForm
      defaultValues={userData}
      isUpdate
      onSuccess={newVals => {
        setContextUserData({
          ...userD,
          ...newVals
        })
      }}
      userName={user.username}
    />
  ) : null
}
export default UpdateProfile
