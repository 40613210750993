import { Grid } from '@mui/material'
import logo from '../assets/logoWide.png'
export const LoadingComponent = () => (
  <Grid
    maxWidth="xl"
    sx={{
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      margin: 'auto',
      height: '100vh'
    }}
  >
    <img
      style={{
        width: '50%'
      }}
      src={logo}
    />
  </Grid>
)
