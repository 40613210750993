import { useEffect } from 'react'

import { Authenticator, useAuthenticator, View } from '@aws-amplify/ui-react'
import '@aws-amplify/ui-react/styles.css'

import { useLocation, useNavigate } from 'react-router'

export function Login() {
  const { route, user } = useAuthenticator(context => [context.route])
  const location = useLocation()
  const navigate = useNavigate()
  let from = location.state?.from?.pathname || '/'
  useEffect(() => {
    if (route === 'authenticated') {
      if (
        user?.signInUserSession?.accessToken?.payload?.[
          'cognito:groups'
        ]?.includes('Admin')
      ) {
        navigate('/dashboard', { replace: true })
      } else {
        navigate(from === '/' ? '/user' : from, { replace: true })
      }
    }
  }, [route, navigate, from])
  return (
    <View className="auth-wrapper">
      <Authenticator
        initialState={location.pathname === '/signUp' ? 'signUp' : 'signIn'}
      ></Authenticator>
    </View>
  )
}
