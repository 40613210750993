import { useAuthenticator } from '@aws-amplify/ui-react'
import _ from 'lodash'
import { useContext, useEffect, useState } from 'react'
// import * as queries from '../graphql/queries/userData.graphql'
import { Auth } from 'aws-amplify'
import { ChecklistSection } from './ChecklistSection'
import { UserContext } from './DataContextProvider'
import OnboardingForm from './Onboarding'
export const User = () => {
  const { user } = useAuthenticator(context => [context.route])
  const [userData, setUserData] = useState()
  const [initialData, setInitialData] = useState()

  const { userData: userD, setUserData: setContextUserData } =
    useContext(UserContext)
  useEffect(() => {
    if (userD) {
      setUserData(userD)
    }
  }, [userD])

  useEffect(() => {
    if (user?.username) {
      Auth.currentUserCredentials().then(({ identityId }) => {
        setInitialData(prev => ({
          ...prev,
          identityId,
          email: user.attributes.email,
          userId: user.username
        }))
      })
    }
  }, [user?.username])

  return (
    <div>
      {user.username && _.isEmpty(userData) && !_.isEmpty(initialData) && (
        <OnboardingForm
          onSuccess={newVals => {
            setUserData({
              ...userD,
              ...newVals
            })
            setContextUserData({
              ...userD,
              ...newVals
            })
          }}
          defaultValues={initialData}
        />
      )}
      {user.username && !_.isEmpty(userData) && (
        <>
          <ChecklistSection
            userData={userData}
            setContextUserData={newVals => {
              setContextUserData({
                ...userD,
                ...newVals
              })
            }}
          />
        </>
      )}
    </div>
  )
}
export default User
