import { GRAPHQL_AUTH_MODE } from '@aws-amplify/api'
import {
  Button,
  Checkbox,
  FormControlLabel,
  Grid,
  TextField
} from '@mui/material'
import { DatePicker } from '@mui/x-date-pickers'
import { API } from 'aws-amplify'
import dayjs from 'dayjs'
import { MuiTelInput } from 'mui-tel-input'
import * as React from 'react'
import {
  createUserDataQuery,
  updateUserdataQuery
} from '../graphql/mutation/userData'
import { pancardValidation, validateField } from './utilities'
export default function OnboardingForm(props) {
  const { clearOnSuccess = true, onSuccess, defaultValues, isUpdate } = props
  const initialValues = {
    firstName: '',
    lastName: '',
    PAN: '',
    phone: '',
    middleName: '',
    dob: '',
    registired: false,
    Address: '',
    userId: '',
    referenceCoupon: '',
    ...defaultValues
  }

  const [firstName, setFirstName] = React.useState(initialValues.firstName)
  const [lastName, setLastName] = React.useState(initialValues.lastName)
  const [middleName, setMiddleName] = React.useState(initialValues.middleName)
  const [referenceCoupon, setReferenceCoupon] = React.useState(
    initialValues.referenceCoupon
  )
  const [dob, setDob] = React.useState(dayjs(initialValues.dob))
  const [PAN, setPAN] = React.useState(initialValues.PAN)
  const [phone, setPhone] = React.useState(initialValues.phone)
  const [registired, setRegistired] = React.useState(initialValues.registired)
  const [Address, setAddress] = React.useState(initialValues.Address)
  const [userId, setUserId] = React.useState(initialValues.userId)
  const [errors, setErrors] = React.useState({})

  const resetStateValues = () => {
    setFirstName(initialValues.firstName)
    setLastName(initialValues.lastName)
    setPAN(initialValues.PAN)
    setPhone(initialValues.phone)
    setAddress(initialValues.Address)
    setUserId(initialValues.userId)
    setErrors({})
  }
  const validations = {
    firstName: [{ type: 'Required' }],
    middleName: [{ type: 'Required' }],
    lastName: [{ type: 'Required' }],
    PAN: [{ type: 'Required' }],
    phone: [
      { type: 'Required' },
      { type: 'Phone' },
      { type: 'LessThanChar', numValues: [16] },
      { type: 'GreaterThanChar', numValues: [14] }
    ],
    dob: [{ type: 'Required' }],
    Address: [{ type: 'Required' }],
    userId: [{ type: 'Required' }]
  }
  const runValidationTasks = async (
    fieldName,
    currentValue,
    getDisplayValue
  ) => {
    const value =
      currentValue && getDisplayValue
        ? getDisplayValue(currentValue)
        : currentValue

    let validationResponse = validateField(value, validations[fieldName])
    if (fieldName === 'PAN') {
      if (!pancardValidation(currentValue)) {
        validationResponse = {
          hasError: true,
          errorMessage: 'Please enter valid PAN number'
        }
      }
    }
    setErrors(errors => ({ ...errors, [fieldName]: validationResponse }))
    return validationResponse
  }

  const onSubmit = async event => {
    event.preventDefault()
    let modelFields = {
      firstName,
      lastName,
      PAN,
      phone,
      Address,
      registired,
      middleName,
      referenceCoupon,
      dob: dayjs(dob).format('YYYY-MM-DD'),
      userId
    }

    const validationResponses = await Promise.all(
      Object.keys(validations).reduce((promises, fieldName) => {
        if (Array.isArray(modelFields[fieldName])) {
          promises.push(
            ...modelFields[fieldName].map(item =>
              runValidationTasks(fieldName, item)
            )
          )
          return promises
        }
        promises.push(runValidationTasks(fieldName, modelFields[fieldName]))
        return promises
      }, [])
    )
    if (validationResponses.some(r => r.hasError)) {
      return
    }
    try {
      Object.entries(modelFields).forEach(([key, value]) => {
        if (typeof value === 'string' && value.trim() === '') {
          modelFields[key] = undefined
        }
      })
      const response = await API.graphql({
        query: isUpdate ? updateUserdataQuery : createUserDataQuery,
        variables: {
          input: {
            ...initialValues,
            ...modelFields
          }
        },
        authMode: GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS
      })
      onSuccess(response.data[isUpdate ? 'updateUserData' : 'createUserData'])
      if (clearOnSuccess) {
        resetStateValues()
      }
    } catch (err) {
      console.log(err)
    }
  }

  return (
    <form onSubmit={onSubmit}>
      <Grid display="flex" justifyContent="center" alignItems="center">
        <Grid
          container
          sx={{
            p: 4,
            maxWidth: 720,
            zIndex: 0
          }}
          justifyContent="center"
          gap={3}
          alignItems="center"
        >
          <Grid item flex textAlign="center" xs={12}>
            Lets get to know you better!
          </Grid>
          <Grid item xs={12}>
            <TextField
              sx={{
                width: '100%'
              }}
              label={'First name'}
              required={true}
              value={firstName}
              onChange={e => {
                let { value } = e.target
                if (errors.firstName?.hasError) {
                  runValidationTasks('firstName', value)
                }
                setFirstName(value)
              }}
              onBlur={() => runValidationTasks('firstName', firstName)}
              helperText={errors.firstName?.errorMessage}
              error={errors.firstName?.hasError}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              sx={{
                width: '100%'
              }}
              label={'Middle name'}
              required={true}
              disabled={false}
              value={middleName}
              onChange={e => {
                let { value } = e.target
                if (errors.middleName?.hasError) {
                  runValidationTasks('middleName', value)
                }
                setMiddleName(value)
              }}
              onBlur={() => runValidationTasks('middleName', middleName)}
              helperText={errors.middleName?.errorMessage}
              error={errors.middleName?.hasError}
            ></TextField>
          </Grid>
          <Grid item xs={12}>
            <TextField
              sx={{
                width: '100%'
              }}
              label={'Last name'}
              required={true}
              disabled={false}
              value={lastName}
              onChange={e => {
                let { value } = e.target
                if (errors.lastName?.hasError) {
                  runValidationTasks('lastName', value)
                }
                setLastName(value)
              }}
              onBlur={() => runValidationTasks('lastName', lastName)}
              helperText={errors.lastName?.errorMessage}
              error={errors.lastName?.hasError}
            ></TextField>
          </Grid>
          <Grid item xs={12}>
            <DatePicker
              sx={{
                width: '100%'
              }}
              format="DD-MM-YYYY"
              label={'Date of Birth'}
              disableFuture
              required={true}
              disabled={false}
              value={dayjs(dob)}
              onChange={value => {
                if (errors.dob?.hasError) {
                  runValidationTasks('dob', value)
                }
                setDob(value)
              }}
              onBlur={() => runValidationTasks('dob', dob)}
              helperText={errors.dob?.errorMessage}
              error={errors.dob?.hasError}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              sx={{
                width: '100%'
              }}
              label={'Pan'}
              required={true}
              disabled={false}
              value={PAN}
              onChange={e => {
                let { value } = e.target
                if (value.length > 10) {
                  return
                }
                if (errors.PAN?.hasError) {
                  runValidationTasks('PAN', value)
                }
                setPAN(value.toUpperCase())
              }}
              onBlur={() => runValidationTasks('PAN', PAN)}
              helperText={errors.PAN?.errorMessage}
              error={errors.PAN?.hasError}
            />
          </Grid>
          <Grid item xs={12}>
            <MuiTelInput
              required
              value={phone}
              defaultCountry="IN"
              onlyCountries={['IN']}
              onChange={value => {
                if (errors.phone?.hasError) {
                  runValidationTasks('phone', value)
                }
                value.replaceAll(/\s/g, '').length <= 13 && setPhone(value)
              }}
              label="Phone"
              placeholder="Phone"
              sx={{
                margin: 0,
                width: { xs: '100%', md: '100%' }
              }}
              size="medium"
              name="phone"
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              sx={{
                width: '100%'
              }}
              label={'Address'}
              required={true}
              disabled={false}
              value={Address}
              onChange={e => {
                let { value } = e.target
                if (errors.Address?.hasError) {
                  runValidationTasks('Address', value)
                }
                setAddress(value)
              }}
              onBlur={() => runValidationTasks('Address', Address)}
              helperText={errors.Address?.errorMessage}
              error={errors.Address?.hasError}
            />
          </Grid>
          {isUpdate ? null : (
            <Grid item xs={12}>
              <TextField
                sx={{
                  width: '100%'
                }}
                label={'Referral code'}
                required={false}
                disabled={false}
                value={referenceCoupon}
                onChange={e => {
                  let { value } = e.target
                  if (value.length < 15) setReferenceCoupon(value)
                }}
              />
            </Grid>
          )}
          <Grid item xs={12}>
            <FormControlLabel
              sx={{
                width: '100%'
              }}
              onChange={e => {
                setRegistired(e.target.checked)
              }}
              checked={registired}
              control={<Checkbox />}
              label={
                <>
                  Are you registered on income tax e-filing portal ?{' '}
                  <i>
                    If not please register over{' '}
                    <a
                      href="https://eportal.incometax.gov.in/iec/foservices/#/pre-login/register"
                      target="_blank"
                    >
                      here.
                    </a>
                  </i>
                </>
              }
            />
          </Grid>
          <Grid
            sx={{
              display: 'flex'
            }}
            item
            xs={12}
            justifyContent="space-between"
          >
            <Button
              children="Clear"
              type="reset"
              onClick={event => {
                event.preventDefault()
                resetStateValues()
              }}
            ></Button>
            <Button
              children={isUpdate ? 'Update' : 'Submit'}
              type="submit"
              variant="contained"
              disabled={Object.values(errors).some(e => e?.hasError)}
            ></Button>
          </Grid>
        </Grid>
      </Grid>
    </form>
  )
}
