import { Box, Button, Grid, Typography } from '@mui/material'
import * as React from 'react'
import { useNavigate } from 'react-router-dom'
import landingImage from '../assets/landing.png'
export const Section1 = () => {
  const navigate = useNavigate()
  return (
    <Grid container spacing={4}>
      <Grid
        sx={{
          justifyContent: 'center',
          alignItems: { md: 'start', xs: 'center' },
          display: 'flex',
          flexDirection: 'column',
          margin: { xs: '32px', md: 0 }
        }}
        item
        xs={12}
        md={6}
      >
        <Typography
          variant="h3"
          sx={{
            color: 'inherit',
            textAlign: 'left',
            pl: { md: '50px', xs: '0' },
            '.highlight': {
              color: '#2C6384'
            }
          }}
        >
          Transform The Way You Approach{' '}
          <span className="highlight">Taxes</span>
        </Typography>
        <Typography
          variant="h5"
          sx={{
            color: '#9FA1A4',
            textAlign: 'left',
            pt: '32px',
            pl: { md: '50px', xs: '0' },
            '.highlight': {
              color: '#2C6384'
            }
          }}
        >
          Let us take the hassle out of tax season and help you save{' '}
          <span className="highlight">TIME & MONEY</span>.
        </Typography>
        <Box
          sx={{
            width: '100%',
            justifyContent: { md: 'start', xs: 'center' },
            pl: { md: '50px', xs: '0' },
            display: 'flex',
            padding: '28px  0'
          }}
        >
          <Button
            onClick={() => navigate('/signUp')}
            variant="contained"
            size="large"
          >
            Get started
          </Button>
        </Box>
      </Grid>
      <Grid
        item
        xs={12}
        md={6}
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center'
        }}
      >
        <img style={{ width: '100%' }} src={landingImage} />
      </Grid>
    </Grid>
  )
}
