import { useAuthenticator } from '@aws-amplify/ui-react'
import MenuIcon from '@mui/icons-material/Menu'
import { Alert } from '@mui/material'
import AppBar from '@mui/material/AppBar'
import Avatar from '@mui/material/Avatar'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Container from '@mui/material/Container'
import IconButton from '@mui/material/IconButton'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import Toolbar from '@mui/material/Toolbar'
import Tooltip from '@mui/material/Tooltip'
import Typography from '@mui/material/Typography'
import { Storage } from 'aws-amplify'
import * as React from 'react'
import { useContext, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import logo from '../assets/logoWide.png'
import { UserContext } from './DataContextProvider'

const pages = [
  'Home',
  'About Us',
  // 'Income Tax',
  'Registrations',
  'Pricing',
  'Blog'
]
const settings = ['Profile', 'Logout']

export const LandingNavBar = () => {
  const [anchorElNav, setAnchorElNav] = useState(null)
  const [anchorElUser, setAnchorElUser] = useState(null)
  const [userData, setUserData] = useState()
  const { route, user, signOut } = useAuthenticator(context => [context.route])
  const { userData: userD } = useContext(UserContext)

  useEffect(() => {
    if (userD) {
      setUserData(userD)
    }
  }, [userD])

  useEffect(() => {
    if (route === 'authenticated' && user.username && !userData?.photoUrl) {
      Storage.configure({ level: 'protected' })

      userData?.Photo &&
        Storage.get(userData?.Photo)
          .then(url => {
            setUserData(prev => ({
              ...prev,
              photoUrl: url
            }))
          })
          .catch(error => {
            console.log(error)
          })
    }
  }, [userData])

  const handleOpenNavMenu = event => {
    setAnchorElNav(event.currentTarget)
  }
  const handleOpenUserMenu = event => {
    setAnchorElUser(event.currentTarget)
  }
  const navigate = useNavigate()
  const handleCloseNavMenu = page => {
    switch (page) {
      case 'About Us': {
        navigate('/aboutUs')
        break
      }
      case 'Home': {
        navigate(route === 'authenticated' ? '/user' : '/')
        break
      }
      case 'Income Tax': {
        navigate('/itr')
        break
      }
      case 'Pricing': {
        new Promise(resolve => {
          return resolve(navigate('/'))
        }).then(() => {
          setTimeout(() => {
            document
              .getElementById('taxCheckboxes')
              .scrollIntoView({ behavior: 'smooth' })
          }, 200)
        })
        break
      }
      case 'Registrations': {
        navigate('/registrations')
        break
      }
      case 'Blog': {
        navigate('/blog')
        break
      }
    }
    setAnchorElNav(null)
  }

  const handleCloseUserMenu = async setting => {
    if (setting === 'Logout') {
      new Promise(resolve => {
        resolve(signOut())
      }).then(() => {
        navigate('/')
      })
    }
    if (setting === 'Profile') {
      navigate('/profile')
    }
    setAnchorElUser(null)
  }

  return (
    <>
      <AppBar
        sx={{
          background: 'white',
          color: 'black',
          position: 'sticky',
          top: 0,
          zIndex: 1
        }}
        position="static"
      >
        <Container sx={{ padding: '16px 0' }} maxWidth="xl">
          <Toolbar disableGutters>
            <Typography
              variant="h6"
              noWrap
              component="a"
              href="/"
              sx={{
                height: 100,
                ml: '50px',
                display: { xs: 'none', md: 'flex' },
                fontFamily: 'monospace',
                fontWeight: 700,
                letterSpacing: '.3rem',
                color: 'inherit',
                textDecoration: 'none'
              }}
            >
              <img src={logo} />
            </Typography>

            <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
              <IconButton
                size="large"
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={handleOpenNavMenu}
                color="inherit"
              >
                <MenuIcon />
              </IconButton>
              <Menu
                id="menu-appbar"
                anchorEl={anchorElNav}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'left'
                }}
                keepMounted
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'left'
                }}
                open={Boolean(anchorElNav)}
                onClose={handleCloseNavMenu}
                sx={{
                  display: { xs: 'block', md: 'none' }
                }}
              >
                {pages.map(page => (
                  <MenuItem key={page} onClick={handleCloseNavMenu}>
                    <Typography textAlign="center">{page}</Typography>
                  </MenuItem>
                ))}
              </Menu>
            </Box>
            <Typography
              variant="h5"
              noWrap
              component="a"
              href=""
              sx={{
                mr: 2,
                height: 70,
                display: { xs: 'flex', md: 'none' },
                flexGrow: 1,
                fontFamily: 'monospace',
                fontWeight: 700,
                letterSpacing: '.3rem',
                color: 'inherit',
                textDecoration: 'none'
              }}
            >
              <img src={logo} />
            </Typography>
            <Box
              sx={{
                flexGrow: 1,
                display: { xs: 'none', md: 'flex' },
                justifyContent: 'end'
              }}
            >
              {pages.map(page => (
                <Button
                  key={page}
                  onClick={() => handleCloseNavMenu(page)}
                  sx={{
                    my: 2,
                    color: 'black',
                    fontSize: '18px',
                    display: 'block'
                  }}
                >
                  {page}
                </Button>
              ))}
            </Box>

            <Box sx={{ flexGrow: 0 }}>
              {route === 'authenticated' ? (
                <>
                  <Tooltip title="Open settings">
                    <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                      <Avatar src={userData?.photoUrl} />
                    </IconButton>
                  </Tooltip>
                  <Menu
                    sx={{ mt: '45px' }}
                    id="menu-appbar"
                    anchorEl={anchorElUser}
                    anchorOrigin={{
                      vertical: 'top',
                      horizontal: 'right'
                    }}
                    keepMounted
                    transformOrigin={{
                      vertical: 'top',
                      horizontal: 'right'
                    }}
                    open={Boolean(anchorElUser)}
                    onClose={handleCloseUserMenu}
                  >
                    {settings.map(setting => (
                      <MenuItem
                        key={setting}
                        onClick={() => handleCloseUserMenu(setting)}
                      >
                        <Typography textAlign="center">{setting}</Typography>
                      </MenuItem>
                    ))}
                  </Menu>
                </>
              ) : (
                <>
                  <Button
                    onClick={() => {
                      navigate('/signIn')
                    }}
                    sx={{
                      fontSize: '18px'
                    }}
                  >
                    Sign In
                  </Button>
                </>
              )}
            </Box>
          </Toolbar>
        </Container>
      </AppBar>
      {userData?.itrStatus && (
        <Alert
          sx={{
            textAlign: 'center',
            justifyContent: 'center'
          }}
          severity={userData?.itrStatus === 'itrFiled' ? 'success' : 'info'}
        >
          Your current ITR status is: <b>{getItrStatus(userData?.itrStatus)}</b>
        </Alert>
      )}
    </>
  )
}
export default LandingNavBar

const getItrStatus = status => {
  switch (status) {
    case 'waitingForIncome':
      return 'Waiting for income Sources'
    case 'waitingForDocs':
      return 'Waiting for documents to be uploaded'
    case 'underProcess':
      return 'ITR preparetion is under progress'
    case 'itrFiled':
      return 'ITR Filed'
    default:
      return 'Under Process'
  }
}
