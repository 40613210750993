import { GRAPHQL_AUTH_MODE } from '@aws-amplify/auth'
import {
  Box,
  Button,
  Card,
  CardContent,
  Checkbox,
  FormControlLabel,
  Grid,
  MenuItem,
  Select,
  Typography
} from '@mui/material'
import { API } from 'aws-amplify'
import _ from 'lodash'
import { useEffect, useMemo, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { v4 } from 'uuid'
import business from '../assets/Business Income.jpg'
import capital from '../assets/Capital Gains.jpg'
import house from '../assets/HOuse Vector.jpg'
import other from '../assets/Other Income.png'
import salary from '../assets/Salary Vector.jpg'
import { updateUserdataQuery } from '../graphql/mutation/userData'
import { createCupons } from '../graphql/mutations'
import { getReferralCode } from './utilities'

export const services = {
  salaryIncome: 'salaryIncome',
  rent: 'rent',
  salary: 'salary',
  rentIncome: 'rentIncome',
  houseLoan: 'houseLoan',
  mutualFund: 'mutualFund',
  immovableProperty: 'immovableProperty',
  fno: 'fno',
  businessTurnoverBelow2cr: 'businessTurnoverBelow2cr',
  professionalIncomeBelow50: 'professionalIncomeBelow50',
  interestIncome: 'interestIncome',
  dividentIncome: 'dividentIncome',
  crypto: 'crypto',
  otherIncome: 'otherIncome'
}
export const ChecklistSection = ({ userData, setContextUserData }) => {
  const [servicesState, setServicesState] = useState({
    salaryIncome: false,
    rent: false,
    salary: 5,
    rentIncome: false,
    houseLoan: false,
    mutualFund: false,
    immovableProperty: false,
    fno: false,
    businessTurnoverBelow2cr: false,
    professionalIncomeBelow50: false,
    interestIncome: false,
    dividentIncome: false,
    crypto: false,
    otherIncome: false
  })

  const [finalQuote, setQuote] = useState(0)
  const [loading, setLoading] = useState()
  const navigate = useNavigate()

  const handleChange = event => {
    setServicesState({
      ...servicesState,
      [event.target.name]:
        event.target.checked === undefined
          ? event.target.value
          : event.target.checked
    })
  }
  const cards = useMemo(
    () => [
      {
        title: 'Income From Salary',
        image: salary,
        checks: [
          {
            title: (
              <>
                I have a job.
                <br />{' '}
                <i style={{ fontSize: '12px' }}>Enter approx value in lacs</i>
              </>
            ),
            val: services.salaryIncome,
            extra: (
              <FormControlLabel
                sx={{
                  '.MuiInputBase-root ': {
                    width: 'calc(100% - 40px)',
                    marginBottom: '16px',
                    marginLeft: '40px'
                  },
                  '&.MuiFormControlLabel-root': {
                    alignItems: 'flex-start',
                    fontSize: '14px',
                    width: '100%'
                  }
                }}
                disabled={!servicesState.salaryIncome}
                name={'salary'}
                defaultValue={5}
                control={
                  <Select
                    onChange={handleChange}
                    defaultValue={5}
                    size="small"
                    disabled={!servicesState.salaryIncome}
                    placeholder="select"
                  >
                    <MenuItem value={5}>{`<= to 5 Lacs`}</MenuItem>
                    <MenuItem value={10}>{`<= to 10 Lacs`}</MenuItem>
                    <MenuItem value={50}>{`<= to 50 Lacs`}</MenuItem>
                    <MenuItem value={100}>{`> 50 Lacs`}</MenuItem>
                  </Select>
                }
                placeholder="salary"
              />
            )
          },
          {
            title: 'I have paid rent',
            val: services.rent
          }
        ]
      },
      {
        title: 'House Property Income',
        image: house,
        checks: [
          {
            title: 'I earned Rental Income',
            val: services.rentIncome
          },
          {
            title: 'I have a housing Loan',
            val: services.houseLoan
          }
        ]
      },
      {
        title: 'Capital Gains',
        image: capital,
        checks: [
          {
            title: 'I have sold Shares/Mutual Funds',
            val: services.mutualFund
          },
          {
            title: 'I have sold Immovable Property/ Capital Assets',
            val: services.immovableProperty
          }
        ]
      },
      {
        title: 'Business/ Profession Income',
        image: business,
        style: {
          alignSelf: 'center',
          width: '80%'
        },
        checks: [
          {
            title: 'I trade in Derivatives (FnO ,etc)',
            val: services.fno
          },
          {
            title: 'Business Turnover below 2 cr (44AD)',
            val: services.businessTurnoverBelow2cr
          },
          {
            title: 'Professional Income below 50L',
            val: services.professionalIncomeBelow50
          }
        ]
      },
      {
        title: 'Income From Other Sources',
        image: other,
        checks: [
          {
            title: 'Interest Income (Savings/FD)',
            val: services.interestIncome
          },
          {
            title: 'Dividend Income',
            val: services.dividentIncome
          },
          {
            title: 'Income from Cryptocurrencies',
            val: services.crypto
          },
          {
            title: 'Any Other Income',
            val: services.otherIncome
          }
        ]
      }
    ],
    [servicesState]
  )

  const updateServices = async () => {
    setLoading(true)
    try {
      if (
        !_.isEqual(
          {
            ...userData,
            services: JSON.stringify({ ...servicesState, quote: finalQuote })
          },
          userData
        )
      ) {
        const response = await API.graphql({
          query: updateUserdataQuery,
          variables: {
            input: {
              ...userData,
              services: JSON.stringify({ ...servicesState, quote: finalQuote })
            }
          },
          authMode: GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS
        })

        if (!userData.services) {
          await API.graphql({
            query: createCupons,
            variables: {
              input: {
                id: v4(),
                code: getReferralCode(response.data.updateUserData),
                discountValue: 10,
                referenceEmail: response.data.updateUserData.email,
                active: true
              }
            },
            authMode: GRAPHQL_AUTH_MODE.API_KEY
          })
        }

        setContextUserData({
          ...response.data.updateUserData
        })
      }
      setLoading(false)
      navigate('/uploadFiles')
    } catch (error) {
      console.log(error)
      setLoading(false)
    }
  }

  useEffect(() => {
    if (
      userData &&
      !_.isEmpty(userData.services) &&
      !_.isEqual(userData.services, servicesState)
    )
      setServicesState(JSON.parse(userData.services))
  }, [userData])

  useEffect(() => {
    const {
      salaryIncome,
      rent,
      salary,
      rentIncome,
      houseLoan,
      mutualFund,
      immovableProperty,
      fno,
      businessTurnoverBelow2cr,
      professionalIncomeBelow50,
      interestIncome,
      dividentIncome,
      crypto,
      otherIncome
    } = servicesState
    let quote = 0
    if (salaryIncome) {
      if (salary <= 5) {
        quote = quote + 750
      } else if (salary <= 10) {
        quote = quote + 1250
      } else if (salary <= 50) {
        quote = quote + 2000
      } else {
        quote = quote + 2500
      }
    }
    if (immovableProperty || mutualFund) {
      quote = quote + 2000
    }
    if (crypto) {
      quote = quote + 1500
    }
    if (fno) {
      quote = quote + 1000
    }
    if (businessTurnoverBelow2cr || professionalIncomeBelow50) {
      quote = quote + 2500
    }
    if (
      !quote &&
      (rentIncome ||
        houseLoan ||
        interestIncome ||
        dividentIncome ||
        otherIncome)
    ) {
      quote = quote + 1500
    }
    setQuote(quote)
  }, [servicesState])

  return (
    <Box
      id="taxCheckboxes"
      sx={{
        padding: {
          md: '0 64px',
          xs: '0 32px'
        }
      }}
    >
      <Grid
        sx={{
          mt: { md: '50px', xs: '50px' },
          textAlign: 'center'
        }}
        container
      >
        <Grid
          item
          sx={{
            width: '100%'
          }}
        >
          <Typography gutterBottom variant="h5" component="div">
            {`Hi ${userData.firstName}, Lets figure out the appropriate tax solution for you for FY
            2023-24.`}
          </Typography>
        </Grid>
        <Grid
          item
          sx={{
            width: '100%'
          }}
        >
          <Typography gutterBottom variant="h5" component="div">
            Please select the applicable options to you.
          </Typography>
        </Grid>
      </Grid>
      <Grid
        container
        sx={{
          mt: { md: '50px', xs: '50px' }
        }}
        spacing={4}
      >
        {cards.map(cardDeails => {
          return (
            <Grid key={cardDeails.title} item xs={12} md={12 / 5}>
              <Card
                sx={{
                  minWidth: '100%',
                  minHeight: '100%',
                  borderRadius: '15px',
                  boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px',
                  position: 'relative',
                  display: 'inline-grid'
                }}
                variant="outlined"
              >
                <CardContent
                  sx={{
                    height: '100%',
                    display: 'flex',
                    flexDirection: 'column'
                  }}
                >
                  <div>
                    <Typography gutterBottom variant="h6" component="div">
                      {cardDeails.title}
                    </Typography>
                    {cardDeails.checks.map(check => {
                      return (
                        <div key={check.val}>
                          <FormControlLabel
                            sx={{
                              '.MuiCheckbox-root': {
                                padding: '0 9px'
                              },
                              '&.MuiFormControlLabel-root': {
                                alignItems: 'flex-start',
                                fontSize: '14px',
                                width: '100%'
                              }
                            }}
                            checked={servicesState[check.val]}
                            name={check.val}
                            control={<Checkbox onChange={handleChange} />}
                            label={check.title}
                          />
                          {check.extra}
                        </div>
                      )
                    })}
                  </div>
                  <img
                    style={{
                      width: '100%',
                      marginTop: 'auto',
                      ...cardDeails.style
                    }}
                    src={cardDeails.image}
                    alt={cardDeails.title}
                  />
                </CardContent>
              </Card>
            </Grid>
          )
        })}
      </Grid>
      <Grid justifyContent="center" sx={{ p: 4 }} container>
        <Grid item xs={12} justifyContent="center">
          <Typography
            gutterBottom
            variant="h6"
            sx={{ textAlign: 'center', fontWeight: 'bold' }}
            component="div"
          >
            Your customised Pricing Plan is for ₹{finalQuote}/- Only
          </Typography>
        </Grid>
        <Grid
          item
          xs={12}
          sx={{
            display: 'flex'
          }}
          justifyContent="center"
        >
          <Button
            disabled={!finalQuote || loading}
            onClick={updateServices}
            variant="contained"
          >
            Proceed to upload document
          </Button>
        </Grid>
      </Grid>
    </Box>
  )
}
