export const createLeads = `mutation CreateLeads($input: CreateLeadsInput!) {
    createLeads(input: $input) {
      capitalGains
      crypto
      email
      futureOptions
      id
      ifb
      phone
      quote
      salary
      salaryIncome
    }
  }`
