import { Container } from '@mui/material'
import * as React from 'react'
import { Footer } from './Footer'
import { Section1 } from './Section1'
import { Section2 } from './Section2'
import { Section3 } from './Section3'
import { WhyChooseUs } from './WhyChooseUs'

export const Landing = () => {
  return (
    <>
      <Container maxWidth="xl">
        <Section1 />
        <Section2 />
        <WhyChooseUs />
        {/* <AboutUs /> */}
        <Section3 />
      </Container>
      <Footer />
    </>
  )
}
export default Landing
