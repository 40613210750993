import { GRAPHQL_AUTH_MODE } from '@aws-amplify/auth'
import { useAuthenticator } from '@aws-amplify/ui-react'
import { createContext, useEffect, useState } from 'react'
// import * as queries from '../graphql/queries/userData.graphql'
import { API } from 'aws-amplify'
import { getUserDatabyId } from '../graphql/queries/userData'
import { LoadingComponent } from './LoadingComponent'
export const UserContext = createContext(null)

export const DataContextProvider = ({ children }) => {
  const { user, route } = useAuthenticator(context => [context.route])
  const [userData, setUserData] = useState()
  const [loading, setLoading] = useState()

  useEffect(() => {
    if (route === 'authenticated' && user?.username) {
      setLoading(true)
      API.graphql({
        query: getUserDatabyId,
        authMode: GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS,
        variables: {
          userId: user.username,
          filter: {
            or: [
              {
                _deleted: {
                  eq: false
                }
              }
            ]
          }
        }
      })
        .then(({ data }) => {
          setUserData(data.byUserId.items[0])
          return data.byUserId.items[0]
        })
        .catch(error => console.log(error))
        .finally(() => {
          setLoading(false)
        })
    }
  }, [user?.username])

  return (
    <UserContext.Provider
      value={{
        userData,
        setUserData
      }}
    >
      {loading ? <LoadingComponent /> : children}
    </UserContext.Provider>
  )
}
export default DataContextProvider
