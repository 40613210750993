import { Grid, Typography } from '@mui/material'
import * as React from 'react'
import landingImage from '../assets/Trust.jpg'
export const Section2 = () => {
  return (
    <Grid
      container
      sx={{
        marginTop: 0,
        background: '#e1f1fe',
        p: '32px',
        flexDirection: { md: 'row', xs: 'column-reverse' }
      }}
    >
      <Grid
        item
        xs={12}
        md={6}
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          pt: 0
        }}
      >
        <img
          style={{ width: '100%', borderRadius: '50%' }}
          src={landingImage}
        />
      </Grid>
      <Grid
        sx={{
          justifyContent: 'center',
          alignItems: { md: 'start', xs: 'center' },
          display: 'flex',
          flexDirection: 'column',
          pt: 0
        }}
        item
        xs={12}
        md={6}
      >
        <Typography
          variant="h4"
          sx={{
            color: 'inherit',
            textAlign: 'left',
            pl: { md: '50px', xs: '0' },
            '.highlight': {
              color: '#2C6384',
              fontWeight: 'bold'
            },
            ul: {
              fontSize: 18
            }
          }}
        >
          <span className="highlight">Precision</span>
          <ul>
            <li>Two step Verification of Tax information</li>
            <li>
              Ensuring all round check for accuracy & compliance, with AIS/TIS
              statements.
            </li>
          </ul>

          <span className="highlight">Trust</span>
          <ul>
            <li>Your data, our priority.</li>
            <li>Real time connect with experts.</li>
          </ul>
          <span className="highlight">Efficiency</span>

          <ul>
            <li>Experienced team for seamless filing.</li>
            <li>Comparision of Old Vs New Regime to optimize tax savings.</li>
            <li>Maximize Savings</li>
          </ul>
        </Typography>
      </Grid>
    </Grid>
  )
}
