export const getUserDatabyId = `query getUserDataByUserId($userId: ID!){
  byUserId(userId: $userId) {
    items{
      id
      firstName
      middleName
      lastName
      PAN
      _version
      additionalNotes
      dob
      registired
      phone
      Address
      Photo
      userId
      identityId
      itrStatus
      email
      referenceCoupon
      services
      files {
        id
        fileName
        file
        proofType
        uploadedOn
      }
    }
  }
}`

export const listUserData = `query ListUserData(
  $filter: ModelUserDataFilterInput
  $limit: Int
  $nextToken: String
  ){
  listUserData(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      Address
      PAN
      additionalNotes
      createdAt
      dob
      identityId
      itrStatus
      email
      referenceCoupon
      firstName
      id
      lastName
      middleName
      phone
      files {
        file
        fileName
        id
        proofType
        uploadedOn
      }
      registired
      services
      userId
      _version
    }
  }
}`
