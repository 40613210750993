import { API, GRAPHQL_AUTH_MODE } from '@aws-amplify/api'
import { useAuthenticator } from '@aws-amplify/ui-react'
import {
  Checkbox,
  FormControlLabel,
  Grid,
  MenuItem,
  Select,
  TextField,
  Typography
} from '@mui/material'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { listUserData } from '../graphql/queries/userData'

import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp'
import Collapse from '@mui/material/Collapse'
import IconButton from '@mui/material/IconButton'
import Paper from '@mui/material/Paper'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import { Storage } from 'aws-amplify'
import dayjs from 'dayjs'
import { uniqueId } from 'lodash'
import { updateUserdataFilesQuery } from '../graphql/mutation/userData'
import { fileFormValues as fileFormValuesFromDeductions } from './Deductions'
import { fileFormValues } from './DocumentsUpload'
import { LoadingComponent } from './LoadingComponent'

export const Dashboard = () => {
  const { user, route, ...all } = useAuthenticator(context => [context.route])
  const [userList, setUserList] = useState()
  const [loading, setLoading] = useState()
  const navigate = useNavigate()
  useEffect(() => {
    setLoading(true)
    if (
      user?.username &&
      user?.signInUserSession?.accessToken?.payload?.[
        'cognito:groups'
      ]?.includes('Admin')
    ) {
      API.graphql({
        query: listUserData,
        authMode: GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS
      })
        .then(({ data }) => {
          setUserList(data.listUserData.items)
        })
        .catch(error => console.log(error))
        .finally(() => {
          setLoading(false)
        })
    } else {
      setLoading(false)
      navigate('/user')
    }
  }, [user?.username])

  return loading || !userList ? (
    <LoadingComponent />
  ) : (
    <>
      <Typography
        sx={{
          ml: '32px'
        }}
        variant="h5"
      >
        User List
      </Typography>
      <Grid
        container
        sx={{
          p: '32px'
        }}
      >
        <TableContainer
          component={Paper}
          sx={{
            p: '32px',
            width: '100%'
          }}
        >
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell />
                <TableCell>Name</TableCell>
                <TableCell align="right">Email</TableCell>
                <TableCell align="right">PAN</TableCell>
                <TableCell align="right">Date of birth</TableCell>
                <TableCell align="right">Phone</TableCell>
                <TableCell align="right">Registered</TableCell>
                <TableCell align="right">Referral Code</TableCell>
                <TableCell align="right" width={500}>
                  Additional Notes
                </TableCell>
                <TableCell align="right">Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {userList?.map(userDetails => (
                <Row userDetails={userDetails} key={userDetails.id} />
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
    </>
  )
}

const Row = ({ userDetails: userDetailsFromProps }) => {
  const [open, setOpen] = useState(false)
  const [userDetails, setUserDetails] = useState(userDetailsFromProps)

  const updateItrStatus = async ({ createdAt, ...userData }) => {
    if (userData.itrStatus) {
      const response = await API.graphql({
        query: updateUserdataFilesQuery,
        variables: {
          input: {
            ...userData
          }
        },
        authMode: GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS
      })
      setUserDetails({ ...userDetails, ...response.data.updateUserData })
    }
  }

  return (
    <>
      <TableRow
        key={userDetails.id}
        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
      >
        <TableCell component="th" scope="row">
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>

        <TableCell component="th" scope="row">
          {userDetails.firstName} {userDetails.middleName}{' '}
          {userDetails.lastName}
        </TableCell>
        <TableCell align="right" component="th" sc scope="row">
          {userDetails.email}
        </TableCell>
        <TableCell align="right" component="th" scope="row">
          {userDetails.PAN}
        </TableCell>
        <TableCell align="right" component="th" scope="row">
          {dayjs(userDetails.dob).format('DD-MM-YYYY')}
        </TableCell>
        <TableCell align="right" component="th" scope="row">
          {userDetails.phone}
        </TableCell>
        <TableCell align="right" component="th" scope="row">
          {userDetails.registired ? 'Yes' : 'No'}
        </TableCell>
        <TableCell align="right" component="th" scope="row">
          {userDetails.referenceCoupon}
        </TableCell>
        <TableCell align="right" component="th" scope="row">
          {userDetails.additionalNotes}
        </TableCell>
        <TableCell align="right">
          <Select
            sx={{
              width: '100px'
            }}
            onChange={e => {
              updateItrStatus({ ...userDetails, itrStatus: e.target.value })
            }}
            value={userDetails.itrStatus}
          >
            <MenuItem value="waitingForIncome">
              Waiting for income sources
            </MenuItem>
            <MenuItem value="waitingForDocs">Document upload pending</MenuItem>
            <MenuItem value="underProcess">
              ITR preperation under process
            </MenuItem>
            <MenuItem value="itrFiled">ITR Filed</MenuItem>
          </Select>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={9}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <ServicesSection services={userDetails.services} />
            <FilesSection
              identityId={userDetails.identityId}
              services={userDetails.services}
              files={userDetails.files}
            />
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  )
}

const ServicesSection = ({ services }) => {
  if (!services) {
    return null
  }
  const formatted = JSON.parse(services)
  return (
    <Grid
      container
      sx={{
        width: '100%'
        // display: ""
      }}
    >
      {Object.keys(formatted).map(service => {
        if (['quote', 'salary'].includes(service)) {
          return (
            <Grid key={uniqueId()} item xs={3}>
              <FormControlLabel
                sx={{
                  display: 'flex',
                  justifyContent: 'flex-end',
                  flexDirection: 'row-reverse',
                  ml: 0,
                  gap: '20px'
                }}
                control={<TextField size="small" />}
                value={formatted[service]}
                label={service + ': '}
              />
            </Grid>
          )
        }
        return (
          <Grid key={uniqueId()} item xs={3}>
            <FormControlLabel
              control={<Checkbox />}
              checked={formatted[service]}
              label={service}
            />
          </Grid>
        )
      })}
    </Grid>
  )
}

const allFiles = [...fileFormValues, ...fileFormValuesFromDeductions]
const FilesSection = ({ services, files, identityId }) => {
  const formattedServices = JSON.parse(services || '{}')
  const [filteredFileList, setFilteredFileList] = useState([])
  useEffect(() => {
    if (services) {
      for (const key in allFiles) {
        const requiredFile = allFiles[key]
        if (
          formattedServices[requiredFile.services] ||
          requiredFile.services === 'all'
        ) {
          const file = files?.find(f => f.proofType === requiredFile.name)
          let url = ''
          if (file) {
            url = Storage.get(file?.file, {
              level: 'protected',
              identityId
            }).then(url => {
              setFilteredFileList(prev => [
                ...prev,
                {
                  ...requiredFile,
                  hasUploaded: !!file,
                  file,
                  url
                }
              ])
            })
          } else {
            setFilteredFileList(prev => [
              ...prev,
              {
                ...requiredFile,
                hasUploaded: !!file,
                file,
                url
              }
            ])
          }
        }
      }
    }
  }, [services])

  if (!services) {
    return null
  }

  return (
    <Grid
      container
      sx={{
        my: '32px',
        width: '100%'
      }}
    >
      {filteredFileList.map(item => {
        return (
          <Grid key={uniqueId()} item xs={6}>
            <Grid
              container
              sx={{
                width: '100%',
                p: '8px',
                fontSize: '16px',
                border: '1px solid grey'
              }}
            >
              <Grid item xs={6}>
                {item.title} :
              </Grid>
              {item.hasUploaded ? (
                <Grid item xs={6}>
                  <a href={item.url} target="_blank">
                    {item.file.fileName}
                  </a>
                </Grid>
              ) : (
                <Grid item xs={6} sx={{ color: 'red' }}>
                  {' '}
                  File not uploaded{' '}
                </Grid>
              )}
            </Grid>
          </Grid>
        )
      })}
    </Grid>
  )
}

export default Dashboard
// [
//   {
//     headerName: 'Name',
//     renderCell: ({ row }) => {
//       return `${row.firstName} ${row.middleName} ${row.lastName}`
//     },
//     width: 250,
//     filterable: false,
//     sortable: false
//   },
//   {
//     field: 'PAN',
//     headerName: 'PAN',
//     width: 100,
//     filterable: false,
//     sortable: false
//   },
//   {
//     field: 'dob',
//     headerName: 'Date of birth',
//     width: 100,
//     filterable: false,
//     sortable: false
//   },
//   {
//     field: 'phone',
//     headerName: 'Phone',
//     width: 180,
//     filterable: false,
//     sortable: false
//   },
//   {
//     field: 'registired',
//     headerName: 'Registired',
//     renderCell: ({ row }) => (row.registired ? 'Yes' : 'No'),
//     width: 100,
//     filterable: false,
//     sortable: false
//   },
//   {
//     field: 'additionalNotes',
//     headerName: 'Additional Notes',
//     flex: '0.5',
//     filterable: false,
//     sortable: false
//   }
// ]
