import { Grid, Typography } from '@mui/material'
import * as React from 'react'
import aboutUs from '../assets/About Us page.jpg'
import { Footer } from './Footer'

export const AboutUs = () => {
  return (
    <>
      <Grid
        container
        sx={{
          p: '32px',
          background: '#e1e7e8',
          justifyContent: 'center',
          flexDirection: { md: 'row', xs: 'column' }
        }}
      >
        <Grid
          maxWidth="xl"
          container
          sx={{
            background: '#e1e7e8',
            flexDirection: { md: 'row', xs: 'column' },
            justifyContent: 'center'
          }}
          spacing={{ md: 4, xs: 0 }}
        >
          <Grid item xs={12} md={6}>
            <Grid
              item
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center'
              }}
              xs={12}
            >
              <Typography
                variant="h4"
                sx={{
                  color: 'inherit',
                  pl: { md: '50px', xs: '0' },
                  textAlign: 'justify',
                  fontWeight: 'normal',
                  '.highlight': {
                    color: '#2C6384'
                  }
                }}
              >
                Thriving towards simplifying taxes, maximising benefits and
                fostering{' '}
                <span className="highlight">Financial Success... </span>
              </Typography>
            </Grid>
            <Grid
              container
              sx={{
                mt: { md: '32px', xs: '32px' },
                justifyContent: 'center'
              }}
              spacing={4}
              xs={12}
              md={10}
            >
              <Typography
                variant="h6"
                sx={{
                  color: 'inherit',
                  textAlign: 'justify',
                  pl: { md: '50px', xs: '0' },
                  ul: {
                    fontSize: 18
                  },
                  li: {
                    marginTop: '50px'
                  },
                  '.highlight': {
                    color: '#2C6384'
                  }
                }}
              >
                <ul>
                  <li>
                    Welcome to{' '}
                    <span className="highlight">The Tax Project</span>, your
                    trusted partner for all your tax preparation needs. Our team
                    of tax experts and chartered accountants are dedicated to
                    providing you with top-notch services
                  </li>
                  <li>
                    We strive to make the tax preparation process as smooth and
                    hassle-free as possible. Our team has years of experience in
                    tax preparation and litigations and stays up-to-date with
                    the latest tax laws and regulations to ensure that your tax
                    filings are optimized and accurate.
                  </li>
                  <li>
                    We believe that personalized attention and open
                    communication are essential to providing our clients with
                    the best possible service. Our team works closely with you
                    to understand your unique tax situation and tailor our
                    services to meet your specific needs, making our every
                    assignment a <span className="highlight">Project</span> in
                    itself.
                  </li>
                </ul>
              </Typography>
            </Grid>
          </Grid>
          <Grid
            sx={{ alignItems: ' center', display: 'flex' }}
            item
            xs={12}
            md={6}
          >
            <img style={{ width: '100%' }} src={aboutUs}></img>
          </Grid>
        </Grid>
      </Grid>
      <Footer />
    </>
  )
}
export default AboutUs
